import React from 'react';
import { navigate, Location } from '@reach/router';
import {
  MenuLink as CoreMenuLink,
  IMenuItemProps,
  safeInvoke,
} from '@adair/core-ui';

export type MenuLinkProps<TState = {}> = 
  IMenuItemProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> &
{
  replace?: boolean;
  state?: TState;
  isActiveOnMatch?: boolean;
  isActiveOnPartial?: boolean;
};

/**
 * Custom Menu Link that works with our routing system - Reach Router.
 * Extends the coreUI MenuItem Component
 */
export const MenuLink = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<MenuLinkProps>
>((props, ref) => {

  // 
  // Helpers
  // 

  const shouldNavigate = (event: React.MouseEvent) => (
    !event.defaultPrevented &&
    event.button === 0 &&
    !(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
  );

  // 
  // Handlers
  // 

  const handleClick = (event: React.MouseEvent) => {
    safeInvoke(props.onClick, event as React.MouseEvent<HTMLAnchorElement>);
    if (shouldNavigate(event) && !!props.href) {
      event.preventDefault();
      navigate(props.href, { replace, state });
    }
  };

  // 
  // Render
  // 

  const {
    onClick,
    replace,
    state,
    isActive,
    isActiveOnMatch,
    isActiveOnPartial,
    ...passProps
  } = props;

  return (
    <Location>
      {({ location }) => {
        const isCurrent = location.pathname === props.href;
        const isPartiallyCurrent = !!props.href && location.pathname.startsWith(props.href);
        const isActive = props.isActive || 
          (props.isActiveOnMatch && isCurrent) ||
          (props.isActiveOnPartial && isPartiallyCurrent);

        return (
          <CoreMenuLink
            ref={ref}
            {...passProps}
            isActive={isActive}
            onClick={handleClick}
          />
        );
      }
    }
    </Location>
  );
});

MenuLink.displayName = 'MenuLink';
