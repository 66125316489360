import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import cn from 'classnames';
import {
  Container,
  Form,
  Box,
  Button,
  Heading,
  validate, validators as v,
  marginClass,
} from '@adair/core-ui';
import { IconArrowRight } from '@adair/core-ui/lib/icons';
import { CreateProfileProps } from '@adair/core-client-utilities/lib/profile';
import { FormApi } from 'informed';

import { SplitTemplate } from '../templates';

import { StoreState, useThunkDispatch } from '../../store';
import { SEO } from '../partials';
import { userActions } from '../../store/user';
import { useNextPage, useUser } from '../../hooks';

type RegisterFormProps = Pick<CreateProfileProps, 'firstName' | 'lastName'> & {
  acceptTerms: boolean;
  email: string;
}

export const RegisterPage: React.FC = (props) => {
  const [formApi, setFormApi] = useState<FormApi<RegisterFormProps>>();
  const navigate = useNavigate();
  const dispatch = useThunkDispatch();
  const nextPage = useNextPage();
  const user = useUser();
  const session = useSelector((state: StoreState) => {
    return state.session;
  })

  function handleFormSubmit (values: RegisterFormProps) {
    if (!user) return;
    
    dispatch(userActions.upsertCustomerProfile.action({
      firstName: values.firstName,
      lastName: values.lastName,
      termsAccepted: values.acceptTerms,
    }));
  }

  useEffect(() => {
    if (user?.customerProfile && formApi) {
      formApi.setValue('firstName', user.customerProfile.firstName);
      formApi.setValue('lastName', user.customerProfile.lastName);
      formApi.setValue('email', user.customerProfile.email);
    }
  }, [session, formApi, user])
  
  useEffect(() => {
    if (user?.customerProfile?.termsAccepted) {
      navigate(nextPage ?? '/', { replace: true });
    }
  }, [user, nextPage, navigate])

  // dont show page until we know what to do based on the state of the user
  if (!user) return null;

  return (
    <SplitTemplate>
      <SEO title={"Profile Setup"} />
      <Container maxWidth={'sm'}>
        <Heading size={2} color="green" margin={[null,null,3]}>Welcome to MyAdair!</Heading>
        <Heading size={4}>Before we get started, let's set up your account.</Heading>
        <Box className={marginClass([8,null,null])} density="relaxed">
          <Box.Body>
            <Form<RegisterFormProps>
              getApi={setFormApi}
              onSubmit={handleFormSubmit}
            >
              {({ formState, formApi }) => {
                return (
                  <>
                    <Form.Field label="Name" errorText={[formState.errors.firstName, formState.errors.lastName]}>
                      <Form.Controls>
                        <Form.Control>
                          <Form.Input
                            field="firstName"
                            type="text"
                            placeholder="First"
                            validate={validate([v.isRequired()], 'First Name')}
                          />
                        </Form.Control>
                        <Form.Control>
                          <Form.Input
                            field="lastName"
                            type="text"
                            placeholder="Last"
                            validate={validate([v.isRequired()], 'Last Name')}
                          />
                        </Form.Control>
                      </Form.Controls>
                    </Form.Field>

                    <Form.Field label="Email">
                      <Form.Control>
                        <Form.Input
                          field="email"
                          readOnly
                          isDisabled
                        />
                      </Form.Control>
                    </Form.Field>

                    <Form.Field errorText={formState.errors.acceptTerms}>
                      <Form.Checkbox
                        field="acceptTerms"
                        label={<span>By using myAdair I accept the <a href={`${process.env.REACT_APP_WEB_URL}/about/privacy-policy`} target="_blank">privacy policy</a></span>}
                        validate={validate([v.isRequired(true, 'Please Accept the terms of service')])}
                      />
                    </Form.Field>

                    <Form.Field className={cn(marginClass([8,null,null]))}>
                      <Form.Controls>
                        <Form.Control span="shrink">
                          <Button
                            type="submit"
                            label="Let's Go"
                            intent="primary"
                            after={<IconArrowRight/>}
                          />
                        </Form.Control>
                      </Form.Controls>
                    </Form.Field>
                  </>
                )
              }}
            </Form>
          </Box.Body>
        </Box>
      </Container>
    </SplitTemplate>
  )
}
