import React from 'react';
import { Container, Columns, Column, Heading, Text } from '@adair/core-ui';
import { IconAlertTriangleFill } from '@adair/core-ui/lib/icons';

import { ErrorTemplate } from '../../templates/error/errorTemplate';
import { SplitTemplate } from '../../templates';

export const Error500Page: React.FC = () => {
  return (
    <SplitTemplate>
      <ErrorTemplate>
        <Container>
          <Columns className="t--center">
            <Column>
              <IconAlertTriangleFill color="yellow" size={24} />
              <Heading size={4}>We are having issues loading the site.</Heading>
              <Text as="p" color="gray600">
                Please try refreshing the page. If problems persist, please <a href={`${process.env.REACT_APP_WEB_URL}/contact`}>contact us</a> for help.
              </Text>
            </Column>
          </Columns>
        </Container>
      </ErrorTemplate>
    </SplitTemplate>
  )
}
