import React, { useEffect, useMemo } from 'react';
import { navigate, Router, useParams } from '@reach/router';
import { find, isUndefined } from 'lodash-es';
import useSWR from 'swr';
import { Container, Heading, isDefined, Loader, Message, Text } from '@adair/core-ui';

import { api } from '../../../util/api';
import { handleUnauthorized } from '../../../util/catch-401';
import { BaseTemplate } from '../../templates';
import { SEO, PageLoadError } from '../../partials';
import { Route } from '../../../components';
import { HomeDashPage } from './home-dash';
import { HomeDocumentsPage } from './home-documents';
import { HomeContactsPage } from './home-contacts';
import { HomeTimelinePage } from './home-timeline';
import { getHomeSiteName } from './home-site-index';
import { useUser } from '../../../hooks';



export const HomesSection: React.FunctionComponent = (props) => {
  const params = useParams();
  const user = useUser();
  const id = params?.id;
  const {
    isValidating: homeSiteIsLoading,
    data: homeSite,
    error: homeSiteError,
  } = useSWR(['home-site', id, user?.id], async (_, id) => {
    if (!id) throw new Error('id must be defined');
    return (await api.homeSite.fetch({ id }).request).data;
  });
  const {
    error: timelineError,
    isValidating: timelineIsLoading,
    data: timeline,
  } = useSWR(['home-schedule', id, user?.id], async (_, id) => {
    if (!id) throw new Error('id must be defined');
    return (await api.homeSite.fetchHomeSiteSchedule({ id }).request).data;
  });
  const baseUrl = homeSite ? `/homes/${homeSite.id}` : '/homes';

  useEffect(() => {
    if (isDefined(timelineError)) {
      handleUnauthorized(timelineError)
    }
    if (isDefined(homeSiteError)) {
      handleUnauthorized(homeSiteError)
    }
  }, [timelineError, homeSiteError])

  const currentPhase = useMemo(() => {
    if (!timeline) return;
    return find(timeline.phases, ['isCurrent', true]);
  }, [timeline]);

  function renderError(error: any) {
    if (homeSiteError.response?.status === 401) {
      return null;
    } else if (homeSiteError.response?.status === 404) {
      return (
        <Message
          intent="danger"
          appearance="fill"
          actionText="Back"
          onAction={() => { navigate('/homes') }}
        >
          <Heading size={1} color="danger">Not Found</Heading>
          <Text>The requested home was not found, or you do not have permissions to view it.</Text>
        </Message>
      )
    } else {
      return (
        <PageLoadError />
      )
    }
  }

  return (
    <BaseTemplate
      navTitle={homeSite ? getHomeSiteName(homeSite) : ''}
      navLinks={[
        {
          label: 'Overview',
          url: baseUrl,
        },
        {
          label: 'Timeline',
          url: `${baseUrl}/timeline/${currentPhase?.slug}`,
          match: `${baseUrl}/timeline/:phase`,
        },
        {
          label: 'Documents',
          url: `${baseUrl}/documents`,
        },
        {
          label: 'Contacts',
          url: `${baseUrl}/contacts`,
        },
      ]}
    >
      <SEO title={`${homeSite?.siteAddress ?? ''}`} />
      <Loader isLoading={homeSiteIsLoading || timelineIsLoading} />

      {isDefined(homeSiteError) && (
        <Container maxWidth="lg" margin={[8, null]}>
          {renderError(homeSiteError)}
        </Container>
      )}

      {homeSite && isUndefined(homeSiteError) &&
        <Router primary={false} basepath={'/homes/:id'}>
          <Route path="/"
            component={() => {
              return <HomeDashPage homeSite={homeSite} phases={timeline?.phases ?? []} />
            }}
          />
          <Route path="documents"
            component={() => {
              return <HomeDocumentsPage homeSite={homeSite} />
            }}
          />
          <Route path="contacts"
            component={() => {
              return <HomeContactsPage homeSite={homeSite} />
            }}
          />
          <Route path="timeline/:phase"
            component={() => {
              return <HomeTimelinePage homeSite={homeSite} phases={timeline?.phases ?? []} />
            }}
          />
        </Router>
      }

    </BaseTemplate>
  );
};
