import React from 'react';
import { Match } from '@reach/router';
import cn from 'classnames';
import {
  Container, Columns, Column,
  Menu,
  Popover,
  Buttons, Button,
  Heading,
  OverflowList,
  MenuItem,
  marginClass, paddingClass,
} from '@adair/core-ui';
import { IconCaretDown } from '@adair/core-ui/lib/icons';

import { MenuLink } from '../../../components';

interface SectionNavLink {
  label: string;
  url: string;
  match?: string;
}

export interface SectionNavProps {
  title?: string;
  links: SectionNavLink[];
}

export const SectionNav: React.FC<SectionNavProps> = (props) => {
  function renderMobileNav() {
    return (
      <Buttons className="justify--center justify--start::sm">
        {props.links.map((link, i) => (
          <Match path={link.match ?? link.url} key={i}>
            {({ match }) => {
              if (!match) return null;
              return (
                <Popover
                  position="bottom"
                  content={
                    <Menu isVertical appearance="pill">
                      {props.links.map((link, i) => (
                        <Match path={link.match ?? link.url} key={i}>
                          {({ match }) => (
                            <MenuLink
                              label={link.label}
                              href={link.url}
                              isActive={!!match}
                            />
                          )}
                        </Match>
                      ))}
                    </Menu>
                  }
                >
                  <Button
                    label={link.label}
                    appearance="outline"
                    after={<IconCaretDown />}
                  />
                </Popover>
              )
            }}
          </Match>
        ))}
      </Buttons>
    )
  }

  function renderMenuLink(link: SectionNavLink, i: number) {
    return (
      <Match path={link.match ?? link.url} key={i}>
        {({ match }) => (
          <MenuLink
            label={link.label}
            href={link.url}
            isActive={!!match}
            className={cn({ 't--bold': match })}
          />
        )}
      </Match>
    )
  }

  function renderMenuOverflow(links: SectionNavLink[]) {
    return (
      <Popover
        content={(
          <Menu isVertical appearance="pill">
            {links.map((link, i) => (
              <Match path={link.url} key={i}>
                {({ match }) => (
                  <MenuLink href={link.url} isActive={!!match} label={link.label} />
                )}
              </Match>
            ))}
          </Menu>
        )}
      >
        <MenuItem>
          More <IconCaretDown color="gray400" marginLeft={2} />
        </MenuItem>
      </Popover>
    )
  }

  return (
    <div className={cn('section-nav')}>
      <Container >
        <Columns align="middle" className="flex--nowrap::md">
          {props.title &&
            <Column span={{ xs: 12, sm: 'shrink' }}>
              <Heading size={2} className={cn('t--center')}>{props.title}</Heading>
            </Column>
          }
          <Column className="d--none d--flex::md" style={{ minWidth: 0 }}>
            <Menu appearance="pill" className={cn(paddingClass([null, 8, null, null]))}>
              <OverflowList
                items={props.links}
                visibleItemRenderer={renderMenuLink}
                overflowRenderer={renderMenuOverflow}
                style={{ width: '100%' }}
              />
            </Menu>
          </Column>
          {(props.links.length > 0) &&
            <Column className="d--none::md">
              {renderMobileNav()}
            </Column>
          }
        </Columns>
      </Container>
    </div>
  )
}
