import React, { Fragment, useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { navigate, useLocation } from '@reach/router';
import cn from 'classnames';
import { FormApi } from 'informed';
import {
  Container,
  Message,
  Form,
  Button,
  Heading,
  marginClass,
  Pane,
  validate, validators as v,
  isDefined,
} from '@adair/core-ui';
import { IconArrowLeft, IconArrowRight } from '@adair/core-ui/lib/icons';

import { SEO } from '../../partials';
import { SplitTemplate } from '../../templates';
import { useNextPage } from '../../../hooks';
import { useThunkDispatch } from '../../../store';
import { sessionActions } from '../../../store/session';
import { LinkButton } from '../../../components';

interface SignUpFormState {
  username: string;
  password: string;
  token: string;
}


export const VerifyLocalPage: React.FC = (props) => {
  const location = useLocation();
  const [email, setEmail] = React.useState<string | null>(null);
  const [token, setToken] = React.useState<string | null>(null);
  const [verify, setVerify] = React.useState<string | null>(null);
  const [formApi, setFormApi] = React.useState<FormApi<SignUpFormState>>();
  const nextPage = useNextPage();
  const dispatch = useThunkDispatch();
  const [formError, updateFormError] = useState<{ message: string }>({
    message: '',
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setEmail(params.get('email'));
    setToken(params.get('token'));
    setVerify(params.get('verify'));

    if (formApi) {
      formApi.setValue('email', params.get('email'));
      formApi.setValue('token', params.get('token') ?? params.get('verify'));
    }
  }, [location.search, formApi]);

  function handleFormSubmit(values: SignUpFormState) {
    updateFormError({
      message: '',
    });
    dispatch(sessionActions.register.local.action({
      password: values.password,
      token: values.token,
    }))
      .then((resp) => {
        if (nextPage) {
          // temp: maintain backwards compatibilty
          navigate(`/welcome/profile?next_page=${nextPage}`);
        } else if (isDefined(verify)) {
          navigate(`/welcome/profile?next_page=/verify/${verify}`)
        } else {
          navigate('/')
        }
      })
      .catch((error: AxiosError) => {
        const errorCode = error.response ? error.response.status : 500;
        if (errorCode === 422) {
          updateFormError({
            message: error.response?.data.message,
          });
        } else if (errorCode === 400) {
          updateFormError({
            message: `The link has expired or is invalid. Please request a new link.`
          });
        } else {
          updateFormError({
            message: `There was an error creating your account. Please try again in a
                      few minutes or contact us if the issue persists.`,
          });
        }
      });
  };

  function renderRegisterForm() {
    return (
      <div>
        <Heading margin={[null, null, 1]}>Sign up for myAdair</Heading>
        <p className={cn("c--gray600", marginClass([null, null, 6]))}>Please finish registration by creating a password for your account.</p>
        { formError.message !== '' &&
          <Message
            intent="danger"
            appearance="outline"
            text={formError.message}
            className={cn(marginClass([null, null, 4]))}
          />
        }
        <Form getApi={setFormApi} onSubmit={handleFormSubmit}>
          {({ formState }) => (
            <Fragment>
              <Form.Field label="Username">
                <Form.Control>
                  <Form.Input field="email" density="relaxed" readOnly />
                </Form.Control>
              </Form.Field>
              <Form.Field label="Password" errorText={formState.errors.password}>
                <Form.Control>
                  <Form.Password field="password" density="relaxed" validate={validate([v.isRequired()])} />
                </Form.Control>
              </Form.Field>
              <Form.Controls className={marginClass([6, null, null])}>
                <Form.Control span={'shrink'}>
                  <LinkButton
                    href={`/sign-up${location.search}`}
                    label={<IconArrowLeft />}
                    appearance="outline"
                  />
                </Form.Control>
                <Form.Control>
                  <Button
                    type="submit"
                    label="Continue"
                    after={<IconArrowRight />}
                    intent="primary"
                    isFullWidth
                  />
                </Form.Control>
              </Form.Controls>
              <Form.Hidden field="token" />
            </Fragment>
          )}
        </Form>
      </div>
    )
  }

  return (
    <SplitTemplate>
      <SEO title="Sign Up" />
      <div className="main-content__interior">
        <Container maxWidth="xs">
          {token || verify
            ? renderRegisterForm()
            : (
              <Fragment>
                <Message intent="success" appearance="fill">
                  <Heading size={2} color="green" margin={[null, null, 2]}>An email has been sent{email ? ` to ${email}` : ''}.</Heading>
                  <div>Please follow the link we've sent to verify your email and finish the sign up process.</div>
                </Message>
                <Pane margin={[4, null, null]}>
                  <LinkButton
                    href="/sign-up"
                    appearance="minimal"
                    before={<IconArrowLeft />}
                    label="Back"
                  />
                </Pane>
              </Fragment>
            )
          }
        </Container>
      </div>
    </SplitTemplate>
  )
}