import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Ability } from '@casl/ability';
import { isNull } from 'lodash-es';
import produce from 'immer';

import { UserState } from './';
import { userActions } from './user-actions';

export const initialUserState: UserState = {
  auth: {
    local: null,
  },
  customerProfile: null,
  disabledRanges: [],
  id: '',
  isCompromised: false,
  isFrozen: false,
  lastLoginAt: null,
  permissions: new Ability(),
  portalTypes: [],
  roles: [],
  impersonate: null,
};

export const userReducer = reducerWithInitialState(initialUserState)
  // Fetch
  .case(userActions.fetch.async.done, (state, payload) => {
    return {...state, ...payload.result};
  })
  // Delete
  .case(userActions.delete.async.done, (state, payload) => {
    return initialUserState;
  })
  // Upsert Customer Profile
  .case(userActions.upsertCustomerProfile.async.done, (state, payload) => {
    return produce(state, (draft) => {
      draft.customerProfile = {...draft.customerProfile, ...payload.result};
    });
  })
  // Update Settings
  .case(userActions.updateCustomerProfileSettings.async.done, (state, payload) => {
    return produce(state, (draft) => {
      draft.customerProfile = {...draft.customerProfile, ...payload.result};
    });
  })
  // Impersonate another user (for employees)
  .case(userActions.impersonate.async.done, (state, payload) => {
    // persist to session storage
    if (isNull(payload.result)) {
      window.sessionStorage.removeItem('impersonate');
    } else {
      window.sessionStorage.setItem('impersonate', payload.result);
    }
    return produce(state, (draft) => {
      draft.impersonate = payload.result;
    });
  })
