import React, { createContext } from 'react';

export interface IRouteBaseProps {

  /** 
   * Whether the user is authorized to view this page.
   * Will show a 401 error if false.
   * 
   * @default true
   */
  isAuthorized?: boolean;
  
  /** 
   * Whether a user must be logged in to view the page.
   * A login page is shown if user is not logged in.
   * 
   * @default false
   */
  isPrivate?: boolean;
}

const defaultContext = {
  isAuthorized: true,
  isPrivate: false,
}

export const RouteContext = createContext<IRouteBaseProps>(defaultContext);

export * from './route';
