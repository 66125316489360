import { useLocation } from "@reach/router";
import { queryParamsToObject } from '@adair/core-client-utilities/lib/location';

import { config } from "../config";

export const useNextPage = (absolute = false) => {
    const location = useLocation();
    const nextPage = queryParamsToObject(location.search)['next_page'];
    
    if (!nextPage) return null;

    const isAbsolute = nextPage.indexOf('://') > 0 || nextPage.indexOf('//') === 0;

    if (absolute && !isAbsolute) return `${config.url}${nextPage}`;
    return nextPage;
}
