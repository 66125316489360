import React, { PropsWithChildren, useContext, useEffect } from 'react';
import {
  RouteComponentProps,
  useNavigate,
  useMatch,
  useLocation,
} from '@reach/router';
import { isPast } from 'date-fns';
import { Pane } from '@adair/core-ui';

import { RouteContext, IRouteBaseProps } from './';
import { useUser, useAuth, useQueryParams } from '../../hooks';
import { showAuthAlert } from '../../util/catch-401';
import { useThunkDispatch } from '../../store';
import { userActions } from '../../store/user';
import { uiActions } from '../../store/ui';

interface IRouteProps extends IRouteBaseProps, RouteComponentProps {
  /** Component to render when route is matched */
  component?: React.ComponentType<any>;
}

export const Route: React.FC<PropsWithChildren<IRouteProps>> = (props) => {
  const context = useContext(RouteContext);
  const navigate =  useNavigate();
  const location = useLocation();
  const dispatch = useThunkDispatch();
  const user = useUser();
  const { isAuthenticated, expiresAt } = useAuth();
  const isAtRegister = useMatch('/welcome/profile');
  const mergedProps = { ...context, ...props };
  const params =  useQueryParams();

  const {
    component: Component,
    isAuthorized, 
    isPrivate,
    ...passProps
  } = mergedProps;

  const needsProfile = !!isPrivate &&
    isAuthenticated &&
    !!user &&
    !user.customerProfile?.termsAccepted

  useEffect(() => {
    if (isPrivate && isAuthenticated === false) {
      navigate(`/login?next_page=${encodeURIComponent(location.pathname + location.search)}`, { replace: true });
    } else if (needsProfile && !isAtRegister) {
      navigate( `/welcome/profile?next_page=${encodeURIComponent(location.pathname + location.search)}`, { replace: true });
    }
  }, [isPrivate, isAuthenticated, needsProfile, isAtRegister, location])

  React.useEffect(() => {
    window.addEventListener('focus', handleWindowFocus);
    return () => {
      window.removeEventListener('focus', handleWindowFocus);
    }
  })
  
  React.useEffect(() => {
    const previewId = params.get('preview-as');
    if (!!previewId && isAuthenticated) {
      dispatch(uiActions.previewMode(true));
      dispatch(userActions.impersonate(previewId));
      params.delete('preview-as');
      navigate(
        `${location.protocol}${location.pathname}${Array.from(params).length ? '?' + params.toString() : ''}`,
        { replace: true }
      )
    }
  }, [params, dispatch, isAuthenticated, location.protocol, location.pathname, navigate]);

  function handleWindowFocus(e: FocusEvent) {
    // check if session is expired
    if (isPrivate && isAuthenticated && isPast(expiresAt)) {
      showAuthAlert()
    }
  }

  // This route is just a group
  if (!Component) {
    return (
      <RouteContext.Provider value={{ isAuthorized, isPrivate }}>
        {props.children}
      </RouteContext.Provider>
    )
  }

  // current session is not authenticated and the route is private
  if (isPrivate && !isAuthenticated) return null;
  
  if (needsProfile && !isAtRegister) return null;

  // user is not authorized to view this route
  if (!isAuthorized) {
    return (
      <Pane padding={[10]}>You are not authorized to view this page.</Pane>
    );
  }

  return <Component {...passProps} />;
};
