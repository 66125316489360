import { navigate } from '@reach/router';
import { alert } from '@adair/core-ui';

/**
  * handle an authorization error
  * * @param err Error
  */
export function handleUnauthorized(err: any) {
  if (err.response && err.response.status === 401) {
    showAuthAlert()
  }
}

/**
 * handle an error if it is a 401 error, otherwise throw it
 * @param err Error
 */
export function catchUnauthorized(err: any) {
  if (err.response && err.response.status === 401) {
    showAuthAlert()
  } else {
    throw err;
  }
}

export function showAuthAlert() {
  const id = 'unauthorized-user'

  if (alert.isActive(id)) return;

  alert.show({
    title: 'You have been signed out',
    body: 'This is likely due to inactivity. Please log back in to your account.',
    size: 'xs',
    isCancelable: false,
    onResolve: () => {
      navigate(`/login?next_page=${encodeURIComponent(window.location.href)}`)
    },
    confirmText: 'Log in',
  }, id)
}