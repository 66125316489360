import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Box,
  Form, Input,
  Button,
  DevelopmentSelector, DevelopmentSelectorProps,
  Dialog,
  Heading,
} from '@adair/core-ui';
import { CustomerProfile } from '@adair/core-client-utilities/lib/customer-profile';

import { StoreState, useThunkDispatch } from '../../../store';
import { userActions } from '../../../store/user';
import { catchUnauthorized } from '../../../util/catch-401';
import { api } from '../../../util/api';
import { BuildAreaResponse, BuildAreaCounty } from '@adair/core-client-utilities/lib/build-area';

export const SettingsPage: React.FC = () => {
  const dispatch = useThunkDispatch();
  const [selectorIsOpen, setSelectorIsOpen] = useState(false);
  const [locationCounty, setLocationCounty] = useState<BuildAreaCounty>();
  const settings = useSelector((state: StoreState) => {
    return state.user.customerProfile?.settings;
  });

  useEffect(() => {
    if (settings?.location?.countyId) {
      api.buildArea.fetchCounty(settings.location.countyId, { includeState: true, includeRegion: true }).request
        .then((resp) => {
          setLocationCounty(resp.data);
        }).catch((err) => {
          console.log(err);
        })
    }
  }, [settings?.location?.countyId]);

  const locationName = locationCounty
    ? `${locationCounty.name} County, ${locationCounty.state?.abbreviation ?? ''}`
    : 'Not Set';

  return (
    <Container maxWidth="md" margin={[8, null]}>
      <Box density="relaxed">
        <Box.Header>
          <Heading size={2}>Settings</Heading>
        </Box.Header>
        <Box.Body>
          <div>
            <Form.Field
              label="Default Build Area"
              description="When browsing the Adair website this will be your default build area"
              descriptionPosition="top"
            >
              <Form.Controls isCombined>
                <Form.Control>
                  <Input
                    field="location"
                    value={locationName}
                    readOnly
                  />
                </Form.Control>
                <Form.Control span="shrink">
                  <Button label="Change" intent="primary" onClick={() => setSelectorIsOpen(true)} />
                </Form.Control>
              </Form.Controls>
            </Form.Field>
          </div>
        </Box.Body>
      </Box>
      <DevelopmentSelectDialog
        isOpen={selectorIsOpen}
        onClose={() => { setSelectorIsOpen(false) }}
        onComplete={({ county }) => {
          // set development
          const location: CustomerProfile['settings']['location'] = {
            countyId: county?.properties.id,
          }
          dispatch(userActions.updateCustomerProfileSettings.action({ location }))
            .catch(catchUnauthorized)
            .catch(() => { })

          // close dialog
          setSelectorIsOpen(false);
        }}
      />
    </Container>
  )
}

type DevelopmentSelectDialogProps = {
  isOpen: boolean;
  onClose?: () => void;
  onComplete?: DevelopmentSelectorProps['onComplete'];
}

function DevelopmentSelectDialog(props: DevelopmentSelectDialogProps) {
  const [data, setData] = useState<BuildAreaResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);


  useEffect(() => {
    setIsLoading(true);
    api.buildArea.fetchMapData().request
      .then((resp) => {
        setData(resp.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, []);

  return (
    <Dialog
      isOpen={props.isOpen}
      size="sm"
      onClose={props.onClose}
    >
      <DevelopmentSelector
        baseStateData={data?.base ?? null}
        stateData={data?.states ?? null}
        regionData={data?.regions ?? null}
        countyData={data?.counties ?? null}
        isLoading={isLoading}
        onComplete={props.onComplete}
      />
    </Dialog>
  )

}