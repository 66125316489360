import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { produce } from 'immer';

import { sessionActions } from './session-actions';
import { SessionState } from '.';
import { userActions } from '../user';

export const initialSessionState: SessionState = {
  createdAt: new Date(),
  expiresAt: new Date(),
  id: '',
  isExpired: false,
  isAuthenticated: null,
  sessionSeconds: 0,
  updatedAt: new Date(),
  userId: '',
  user: null,
};

export const sessionReducer = reducerWithInitialState(initialSessionState)
  // Fetch
  .case(sessionActions.fetch.async.done, (state, payload) => {
    return produce<SessionState>(state, (draft) => {
      Object.assign(draft, payload.result);
      draft.isAuthenticated = true;
    });
  })
  .case(sessionActions.fetch.async.failed, (state, payload) => {
    return produce<SessionState>(state, (draft) => {
      draft.isAuthenticated = false;
    });
  })
  // Login & Local Sign Up
  .cases(
    [sessionActions.logIn.local.async.done, sessionActions.register.local.async.done],
    (state, payload) => {
      return produce<SessionState>(state, (draft) => {
        Object.assign(draft, payload.result);
        draft.isAuthenticated = true;
      });
  })
  // Logout & User Delete
  .cases(
    [sessionActions.logOut.async.done, userActions.delete.async.done],
    (state, payload) => {
      return { ...initialSessionState, isAuthenticated: false };
    }
  )
