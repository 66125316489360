import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { uiActions } from '../../store/ui';

export interface SEOProps {
  title?: string;
  description?: string;
  children?: never;
}

export const SEO: React.FC<SEOProps> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const filteredProps = cleanObject({ pageTitle: props.title, pageDescription: props.description});
    dispatch(uiActions.SEO(filteredProps))
  }, [props, dispatch])

  return null;
}

function cleanObject(obj: Record<string, any>) {
  return Object.keys(obj).reduce((acc: Record<string, any>, key) => {
    if (obj[key] !== undefined) acc[key] = obj[key];
    return acc;
  }, {})
}
