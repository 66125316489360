import React, { useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { findIndex } from 'lodash-es';
import { Overlay, Close, Container, Buttons, Button } from '@adair/core-ui';
import { WebAsset } from '@adair/core-client-utilities/lib/web-asset/types';
import { IconChevronLeft, IconChevronRight } from '@adair/core-ui/lib/icons';

export interface ImageGalleryOverlayProps {
  assets: WebAsset[];
  children?: never;
  isOpen: boolean;
  onClose?: () => void;
  onRemove?: (asset: WebAsset) => void;
  api?: React.MutableRefObject<ImageGalleryApi | undefined>;
}

export interface ImageGalleryApi {
  goToId: (id: string) => void,
  goToIndex: (id: number) => void
}

// Constants

export const wrap = (min: number, max: number, v: number) => {
  const rangeSize = max - min;
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

const variants = {
  enter: ({ direction, distance = 1000 }: { direction: number, distance: number }) => {
    return {
      x: direction > 0 ? distance : -distance,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: ({ direction, distance = 1000 }: { direction: number, distance: number }) => {
    return {
      zIndex: 0,
      x: direction < 0 ? distance : -distance,
      opacity: 0,
    }
  },
}

const swipeConfidenceThreshold = 10000
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity
}


export const ImageGalleryOverlay: React.FC<ImageGalleryOverlayProps> = (props) => {

  const [[page, direction], setPage] = React.useState([0, 0]);
  const containerRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (props.api) {
      props.api.current = {
        goToId,
        goToIndex
      }
    }
  })

  if (!props.assets || props.assets.length === 0) return null;

  const imageIndex: number = wrap(0, props.assets.length, page)
  const distance = containerRef.current?.getBoundingClientRect().width ?? 1000;


  //
  // Actions
  //

  function goToId(id: string) {
    const page = findIndex(props.assets, ['id', id]);
    if (page >= 0) {
      goToIndex(page);
    }
  }

  function goToIndex(index: number) {
    const imageIndex: number = wrap(0, props.assets.length, page)

    const offset = index - imageIndex;
    setPage([page + offset, offset]);
  }

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection])
  }

  const prev = () => {
    paginate(-1)
  }

  const next = () => {
    paginate(1)
  }

  // 
  // Render
  // 

  return (
    <Overlay
      isOpen={props.isOpen}
      hasBackdrop
      onClose={props.onClose}
      isClosedOnEscape
      isClosedOnOutsideClick
      enforceFocus
      autoFocus={true}
      transitionDuration={350}
      backdropClassName={'bg--gray900'}
    >
      <div className="image-gallery" >
        <Container className="image-gallery__header t--center c--white" >
          <div className="slide-counter">
            <span className="current">{isNaN(imageIndex) ? '' : imageIndex + 1}</span>
            <span className="divider">/</span>
            <span className="total">{props.assets.length}</span>
          </div>
        </Container>
        <div className={'image-gallery__content'} ref={containerRef} >
          <AnimatePresence initial={false} custom={{ direction, distance: distance + 48 }}>
            <motion.div
              className="image-gallery__item"
              key={page}
              custom={{ direction, distance: distance + 48 }}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 150, damping: 30 },
                opacity: { duration: 0.4 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x)
                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1)
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1)
                }
              }}
            >
              {!!props.assets[imageIndex] &&
                <img
                  draggable={false}
                  src={props.assets[imageIndex].url}
                />
              }
            </motion.div>
          </AnimatePresence>
        </div>
        <Buttons className="image-gallery__controls" flexJustify="center">
          <Button isSquare onClick={prev} label={<IconChevronLeft />} />
          <Button isSquare onClick={next} label={<IconChevronRight />} />
        </Buttons>
        <Close onClick={props.onClose} size="large" appearance="fill" />
      </div>
    </Overlay >
  )
}