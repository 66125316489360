import React, { useEffect } from 'react';
import { useApi } from '@adair/core-client-utilities/lib/hooks';
import {
  Box,
  Buttons, AnchorButton,
  Column, Columns, Container,
  Loader,
  Pane,
  Heading,
  isDefined,
} from '@adair/core-ui';

import { api } from '../../util/api';
import { BaseTemplate } from '../templates';
import { PageLoadError, SEO } from '../partials';
import { handleUnauthorized } from '../../util/catch-401';

export const ResourcesPage: React.FC = () => {
  const { data, error, isLoading } = useApi(api.resources.fetchAll)

  useEffect(() => {
    if (isDefined(error)) {
      handleUnauthorized(error)
    }
  }, [error])

  return (
    <BaseTemplate navTitle="Resources">
      <SEO title="Resources" />
      <Loader isLoading={isLoading} />

      {error &&
        <Container maxWidth="lg" margin={[8,null]}>
          <Loader isLoading={isLoading && !error} />
          <PageLoadError />
        </Container>
      }
      
      <Container margin={[8, null, 10]} maxWidth="xl">
        <Columns>
          {data?.map((resource) => {
            return (
              <Column span={{ xs: 12, sm: 6, lg: 4 }} key={resource.id}>
                <Box appearance="fill">
                  {resource.previewUrl &&
                    <Pane padding={[4, 4,null]} className="d--flex align-items--center justify--center" style={{ height: 300 }}>
                      <img src={resource.previewUrl} className="img-fluid rounded--md elevate--1" style={{ maxHeight: '100%' }} />
                    </Pane>
                  }
                  <Pane padding={[6]} className="t--center">
                    <Heading size={1} color="gray400" margin={[null, null, 2]} className="t--uc">{resource.resourceType}</Heading>
                    <Heading size={3} >{resource.title}</Heading>
                  </Pane>
                  <Box.Footer>
                    {resource.resourceType === 'file' &&
                      <Buttons density="compact" className="justify--center">
                        <AnchorButton label="View" href={resource.url} target="_blank" appearance="outline" />
                      </Buttons>
                    }
                  </Box.Footer>
                </Box>
              </Column>
            )
          })}
        </Columns>
      </Container>
    </BaseTemplate>
  )
}
