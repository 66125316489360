import React from 'react';
import cn from 'classnames';
import uuid from 'uuid/v4';
import { find, unionBy, reject } from 'lodash-es';
import { IProps, safeInvoke, Message } from '@adair/core-ui';

import { bannerClasses } from '../index';
import { bannerManager, BannerAction } from './banner-manager';
import { IBannerOptions } from './banner';

type IBannerProps = IBannerOptions & { id: string };

export interface IBannerContainerState {
  banners: IBannerProps[];
}

export class BannerContainer extends React.Component<IProps,IBannerContainerState> {
  //
  // static Props
  //

  static displayName = 'BannerContainer';

  state: IBannerContainerState = {
    banners: [],
  };

  //
  // Actions
  //

  show(options: IBannerOptions, id?: string) {
    const banner = this._createBannerOptions(options, id);
    let delay = 0;

    if (!!id) {
      this.setState(prevState => {
        const updatedBanners = reject(prevState.banners, ['id', id]);

        if (updatedBanners.length < prevState.banners.length) {
          delay = 350;
        }

        return { banners: updatedBanners };
      });
    }

    setTimeout(() => {
      this.setState(prevState => {
        return { banners: [banner, ...prevState.banners] };
      });
    }, delay);

    return banner.id;
  }

  update(options: IBannerOptions, id: string) {
    this.setState(prevState => {
      const toast = find(prevState.banners, ['id', id]);
      if (!toast) return null;

      const updatedToast = { ...toast, ...options };

      return {
        banners: unionBy([updatedToast], prevState.banners, 'id'),
      };
    });
  }

  dismiss(id: string, timeoutExpired = false) {
    this.setState(({ banners }) => ({
      banners: banners.filter(t => {
        const matchesKey = t.id === id;
        if (matchesKey) {
          safeInvoke(t.onDismissed);
        }
        return !matchesKey;
      }),
    }));
  }

  clear() {
    this.state.banners.map(t => safeInvoke(t.onDismissed));
    this.setState({ banners: [] });
  }

  getBanners() {
    return this.state.banners;
  }

  isBannerActive(id: string) {
    return !!find(this.state.banners, ['id', id]);
  }

  //
  // Helpers
  //

  _isNewBannerKey(id: string) {
    return this.state.banners.every(toast => toast.id !== id);
  }

  _createBannerOptions(
    options: IBannerOptions,
    id = `banner-${uuid()}`,
  ): IBannerProps {
    const defaultProps: Partial<IBannerProps> = {
      isDismissable: true,
    };

    return { ...defaultProps, ...options, id };
  }

  _getDismissHandler = (banner: IBannerProps) => () => {
    this.dismiss(banner.id);
  };

  //
  // Handlers
  //


  //
  // Lifecycle
  //

  componentDidMount() {
    bannerManager
      .on(BannerAction.SHOW, ({ props, id }) => this.show(props, id))
      .on(BannerAction.UPDATE, ({ props, id }) => this.update(props, id))
      .on(BannerAction.DISMISS, ({ id, timeoutExpired }) =>
        this.dismiss(id, timeoutExpired),
      )
      .on(BannerAction.CLEAR, () => this.clear())
      .emit(BannerAction.CONTAINER_MOUNT, { containerInstance: this });
  }

  componentWillUnmount() {
    bannerManager
      .off(BannerAction.SHOW)
      .off(BannerAction.CLEAR)
      .off(BannerAction.DISMISS)
      .emit(BannerAction.CONTAINER_UNMOUNT, {});
  }

  //
  // Render
  //

  _renderBanner = (banner: IBannerProps) => {
    const { id, ...messageProps} = banner;
    return (
      <Message
        {...messageProps}
        appearance="fill"
        isBanner
        key={id}
        onDismiss={ messageProps.isDismissable ? this._getDismissHandler(banner) : undefined}
      />
    );
  };

  _renderBanners() {
    const { banners: toasts } = this.state;
    return toasts.map(this._renderBanner)
  }

  render() {
    const classes = cn(bannerClasses.CONTAINER, this.props.className);
    return (
      <div className={classes} >
        {this._renderBanners()}
      </div>
    );
  }
}
