import React from 'react'
import {Text, Heading, Message } from "@adair/core-ui";

export const PageLoadError = () => {
  return (
    <Message
      appearance="fill"
      intent="danger"
      actionText="Reload"
      onAction={() => { window.location.reload() }}
    >
      <Heading size={1} color="danger" margin={[null, null, 1]}>
        There was an error loading this page
      </Heading>
      <Text color="gray800-alpha">
        Please try reloading the page. If the problem persists,
        please <a href={`${process.env.REACT_APP_WEB_URL}/contact`}>contact us</a> for help.
      </Text>
    </Message>
  )
}
