import React from 'react';
import cn from 'classnames';
import { WebTour } from '@adair/core-client-utilities/lib/web-tour';
import {
  Columns, Column,
  Tooltip,
  Close,
  Buttons, Button,
  Heading,
  paddingClass,
  safeInvoke,
  toaster,
  IProps,
  Pane,
  Message,
} from "@adair/core-ui";
import { IconArrowRight } from '@adair/core-ui/lib/icons';

import { api } from '../../../util/api';

export interface TourCardProps extends IProps {
  tour: WebTour;
  onUnFavoriteStart?: () => void;
  onUnFavoriteSuccess?: () => void;
  onUnFavoriteFailure?: () => void;
}

export const TourCard: React.FC<TourCardProps> = (props) => {

  function unfavoriteTour(e?: React.SyntheticEvent) {
    e?.preventDefault();
    safeInvoke(props.onUnFavoriteStart);

    api.favorite.unfavoriteTour(props.tour.id).request
      .then(() => {
        safeInvoke(props.onUnFavoriteSuccess);
      })
      .catch((err) => {
        toaster.danger({ text: 'There was an uknown error removing this tour' })
        safeInvoke(props.onUnFavoriteFailure);
      })
  }

  function renderInner() {
    return (
      <div
        className="tour-card__inner"
        style={{
          backgroundImage: `url(${props.tour.heroImage})`,
        }}
      >
        <div className="tour-card__overlay d--flex flex--column justify--between">
          <div className="unfavorite">
            <Tooltip content="Remove from Favorites" referenceTagName="span" hoverOpenDelay={500}>
              <Close size="large" onClick={unfavoriteTour} className={'-light'} />
            </Tooltip>
          </div>
          <div>
            <Columns className={paddingClass([6, 4, null, 8])}>
              <Column>
                <Heading size={"sm"} color="yellow" className="t--uc">Virtual Tour</Heading>
                <Heading size={4} color="white">{props.tour.name}</Heading>
              </Column>
            </Columns>
          </div>
          {!props.tour.isArchived &&
            <Buttons flexJustify="center">
              <Button
                label="View"
                intent="warning"
                after={<IconArrowRight />}
              />
            </Buttons>
          }
          <Pane style={{ backgroundColor: 'rgba(255,255,255,.8)' }}>
            {props.tour.isArchived &&
              <Message
                heading="No Longer Available"
                appearance="fill"
                isBanner
                intent="danger"
                actionText="Remove"
                onAction={unfavoriteTour}
                density="compact"
              />
            }
          </Pane>
        </div>
      </div>
    )
  }

  if (props.tour.isArchived) return (
    <div
      className={cn('tour-card', props.className)}
      style={props.style}
    >
      {renderInner()}
    </div>
  )
  return (
    <a
      href={`${process.env.REACT_APP_WEB_URL}/homes/tour/redirect/${props.tour.slug}`}
      className={cn('tour-card', props.className)}
      style={props.style}
      target="_blank"
    >
      {renderInner()}
    </a>
  )
}
