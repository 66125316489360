import { bannerManager, BannerAction } from './banner-manager';
import uuid from 'uuid/v4';

import { BannerContainer } from './banner-container';
import { Intent, isFunction, IMessageProps, IIntentProps } from '@adair/core-ui';

interface BannerDispatch {
  action: BannerAction;
  params: Record<string, any>;
}

let container: BannerContainer | null = null;
let queue: BannerDispatch[] = [];

export type IBannerOptions = Pick<IMessageProps, 'actionText' | 'heading' | 'icon' | 'text' | 'showIcon' | 'onAction' | 'onDismissed'>
& IIntentProps
& {
  isDismissable?: boolean;
}

function dispatchBanner(props: IBannerOptions, id?: string) {
  const verifiedId = id ? id : `toast-${uuid()}`;
  if (container !== null) {
    bannerManager.emit(BannerAction.SHOW, { props, id: verifiedId });
  } else {
    queue.push({ params: { props, id: verifiedId }, action: BannerAction.SHOW });
  }

  return verifiedId;
}

export const banner = {
  /**
   * Show a Toast
   */
  show: (props: IBannerOptions, id?: string) => dispatchBanner(props, id),

  /**
   * Shortcuts for Toasts with a specific intent
   */
  primary: (props: IBannerOptions, id?: string) =>
    dispatchBanner({ ...props, intent: Intent.PRIMARY }, id),

  info: (props: IBannerOptions, id?: string) =>
    dispatchBanner({ ...props, intent: Intent.INFO }, id),

  success: (props: IBannerOptions, id?: string) =>
    dispatchBanner({ ...props, intent: Intent.SUCCESS }, id),

  warning: (props: IBannerOptions, id?: string) =>
    dispatchBanner({ ...props, intent: Intent.WARNING }, id),

  danger: (props: IBannerOptions, id?: string) =>
    dispatchBanner({ ...props, intent: Intent.DANGER }, id),

  /**
   * Remove toast
   */
  dismiss: (id: string) => {
    if (container !== null) {
      bannerManager.emit(BannerAction.DISMISS, { id });
    } else {
      queue.push({ params: { id }, action: BannerAction.SHOW });
    }
  },

  /**
   * Do nothing until the container is mounted. Reassigned later
   */
  isActive: (id: string) => {
    if (container) {
      return container.isBannerActive(id);
    }
    return false;
  },

  getToasts: () => {
    if (container) {
      return container.getBanners();
    }
    return null;
  },

  /**
   * Update an existing toast.
   * New props will be merged with existing props.
   * Nothing will happen if the toast with given id does not exist
   */
  update: (toastId: string, props: IBannerOptions) => {
    // if you call update directly after showing, nothing will be displayed. So
    // we have to defer the update with setTimeout
    setTimeout(() => {
      if (container !== null) {
        bannerManager.emit(BannerAction.UPDATE, { props, id: toastId });
      } else {
        queue.push({
          action: BannerAction.UPDATE,
          params: { props, id: toastId },
        });
      }
    }, 0);
  },

  /**
   * Track changes. The callback gets the number of toast displayed
   */
  onChange: (callback: any) => {
    if (isFunction(callback)) {
      bannerManager.on(BannerAction.ON_CHANGE, callback);
    }
  },

  /**
   * Remove all toasts
   */
  clear: () => {
    if (container !== null) {
      bannerManager.emit(BannerAction.CLEAR, {});
    } else {
      queue.push({ params: {}, action: BannerAction.CLEAR });
    }
  },
};

/**
 * Wait until the BannerContainer is mounted to dispatch the toast
 * and attach isActive method
 */
bannerManager
  .on(BannerAction.CONTAINER_MOUNT, (params) => {
    const { containerInstance } = params as {containerInstance: BannerContainer};
    container = containerInstance;

    queue.forEach(item => {
      bannerManager.emit(item.action, item.params);
    });

    queue = [];
  })
  .on(BannerAction.CONTAINER_UNMOUNT, () => {
    container = null;
  });
