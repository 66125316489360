import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { Link, navigate, useLocation } from '@reach/router';
import cn from 'classnames';
import {
  Container,
  Message,
  Form,
  Buttons, Button,
  Text, Heading,
  marginClass,
  validate, validators as v,
  isDefined,
  Pane,
} from '@adair/core-ui';
import {
  IconMail,
  IconArrowLeft,
  IconSocialGoogle
} from '@adair/core-ui/lib/icons';
import { queryParamsToObject } from '@adair/core-client-utilities/lib/location';

import { sessionActions } from '../../../store/session';
import { useThunkDispatch } from '../../../store';
import { SEO } from '../../partials';
import { SplitTemplate } from '../../templates';
import { useNextPage } from '../../../hooks';


interface LoginFormState {
  username: string;
  password: string;
  rememberMe: boolean;
}

export const LoginPage: React.FC = (props) => {
  const dispatch = useThunkDispatch();
  const nextPage = useNextPage(true);
  const [formError, updateFormError] = useState<{ message: string }>({
    message: '',
  });
  const [useLocalForm, setUseLocalForm] = useState(false);
  const location = useLocation();
  const { verify, email } = queryParamsToObject(location.search);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const failMessage = params.get('fail_message')
    if (failMessage) {
      params.delete('fail_message')
      window.history.replaceState(
        null,
        document.title,
        `${location.protocol}${location.pathname}?${params.toString()}`
      );
      updateFormError({ message: failMessage });
    }
  }, []);

  const handleFormSubmit = (values: LoginFormState) => {
    updateFormError({ message: '' })
    dispatch(sessionActions.logIn.local.action({
      username: values.username,
      password: values.password,
      rememberMe: true,
    }))
      .then((resp) => {
        if (isDefined(nextPage)) {
          navigate(nextPage);
        } else if (isDefined(verify)) {
          navigate(`/verify/${verify}`)
        } else {
          navigate('/')
        }
      })
      .catch((error: AxiosError) => {
        const errorCode = error.response ? error.response.status : 500;
        if (errorCode === 401) {
          updateFormError({
            message: `The username / password combination does not match our
          records`,
          });
        } else {
          updateFormError({
            message: `There was an error logging you in. Please try again in a
          few minutes or contact us if the issue persists.`,
          });
        }
      });
  };

  function loginViaGoogle() {
    dispatch(sessionActions.logIn.oauth.action({
      provider: 'google',
      successRedirect: nextPage ? nextPage : verify ? `/verify/${verify}` : '/',
      failureRedirect: location.href,
    }))
  }

  function renderOauthOptions() {
    return (
      <div>
        <Buttons density="relaxed">
          <Button
            isFullWidth
            label="Continue with Google"
            intent="primary"
            before={<IconSocialGoogle />}
            onClick={loginViaGoogle}
          />
          <Button
            isFullWidth
            label="Continue with Email / Password"
            appearance="outline"
            before={<IconMail />}
            onClick={() => { setUseLocalForm(true); updateFormError({ message: '' }) }}
          />
        </Buttons>
      </div>
    )
  }

  function renderLoginForm() {
    return (
      <div>
        <Form
          onSubmit={handleFormSubmit}
        >
          {({ formState }) => (
            <div>
              <Form.Field label="Email" errorText={formState.errors.username}>
                <Form.Control>
                  <Form.Input
                    field="username"
                    density="relaxed"
                    validate={validate([v.isRequired(), v.isEmail()])}
                  />
                </Form.Control>
              </Form.Field>
              <Form.Field errorText={formState.errors.password}>
                <Pane className={cn('d--flex', 'justify--between')} margin={[null, null, 2]}>
                  <label className={cn('field__label')}>Password</label>
                  <Link to="/password-reset" className={cn('f--sm')}>Forgot Password?</Link>
                </Pane>
                <Form.Controls>
                  <Form.Control>
                    <Form.Password
                      field="password"
                      density="relaxed"
                      validate={validate([v.isRequired()])}
                    />
                  </Form.Control>
                </Form.Controls>
              </Form.Field>
              <Form.Field className={marginClass([8, null, null])}>
                <Form.Controls>
                  <Form.Control span="shrink">
                    <Button
                      appearance="outline"
                      shape="rounded"
                      label={<IconArrowLeft />}
                      onClick={() => { setUseLocalForm(false); updateFormError({ message: '' }); }}
                    />
                  </Form.Control>
                  <Form.Control>
                    <Button
                      type="submit"
                      intent="primary"
                      shape="rounded"
                      label="Sign In"
                    />
                  </Form.Control>
                </Form.Controls>
              </Form.Field>
            </div>
          )}
        </Form>
      </div>
    )
  }

  return (
    <SplitTemplate>
      <SEO title="Login" />
      <div className="main-content__interior">
        <Container maxWidth="xs">
          <Heading size={4}>Sign in to myAdair</Heading>
            <Text color="gray600" margin={[1, null, 6]}>
              Please select an authentication method.
            </Text>
          {formError.message !== '' &&
            <Message intent="danger" appearance="fill" text={formError.message} className={marginClass([null, null, 6])} showIcon />
          }
          {useLocalForm
            ? renderLoginForm()
            : renderOauthOptions()
          }
          <Text margin={[4, null, null]} align="center" color="gray600">
            Don't have an account? &nbsp;
            <strong><Link to={`/sign-up/${location.search}`}>Sign up</Link></strong>
          </Text>
        </Container>
      </div>
    </SplitTemplate>
  )
}
