import React, { Fragment, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import {
  Container, Box,
  Form,
  validate, validators as v,
  AnchorButton, Button, Buttons,
  marginClass,
  Heading,
  alert,
  toaster,
  Dialog,
  Loader,
  Pane,
} from '@adair/core-ui';
import { FormApi } from 'informed';

import { SEO } from '../../partials';
import { userActions } from '../../../store/user';
import { User } from '@adair/core-client-utilities/lib/user';
import { useUser } from '../../../hooks';
import { api } from '../../../util/api';
import { StoreState, useThunkDispatch } from '../../../store';
import { catchUnauthorized } from '../../../util/catch-401';
import { navigate } from '@reach/router';

interface PasswordFormState {
  oldPass: string;
  newPass: string;
  confirmPass: string;
}

interface ProfileFormProps {
  user: User;
}

export const AccountPage: React.FunctionComponent = (props) => {
  const dispatch = useThunkDispatch();
  const user = useUser();
  const [isDeleting, setIsDeleting] = useState(false);

  //
  // Actions
  //

  const deleteAccount = () => {
    setIsDeleting(true)
    dispatch(userActions.delete())
      .then(() => {
        setIsDeleting(false);
        navigate('/');
      })
      .catch(catchUnauthorized)
      .catch(() => {
        toaster.danger({heading: 'Unable to Delete', text: 'An unknown error ocurred.'})
        setIsDeleting(false);
      })
  };

  const confirmAccountDeletion = () => {
    alert.danger({
      title: 'Are you sure you want to delete your account?',
      body: 'This action can not be undone.',
      isClosedOnOutsideClick: true,
      actions: [
        {
          label: 'Delete',
          onClick: deleteAccount,
          isPrimary: true,
        },
      ],
    });
  };


  //
  // Render
  //

  return (
    <Fragment>
      <SEO title="My Account" />
      <Container maxWidth="md" margin={[8, null]}>
        <Box density="relaxed">
          <Box.Header >
            <Heading size={2}>Profile</Heading>
          </Box.Header>
          <Box.Body>
            <ProfileForm />
          </Box.Body>
        </Box>

        {!!user?.auth?.local &&
          <Box density="relaxed" margin={[8, null, null]} >
            <Box.Header>
              <Heading size={2}>Change Password</Heading>
            </Box.Header>
            <Box.Body>
              <PasswordResetForm />
            </Box.Body>
          </Box>
        }
        <Box density="relaxed" intent="danger" className={cn(marginClass([8, null, null]))}>
          <Box.Header>
            <Heading size={2}>Delete Account</Heading>
          </Box.Header>
          <Box.Body>
            <Form.Field
              description={`
                Deleting your account is permanent, please make sure you are
                certain before continuing.
              `}
            >
              <Form.Controls>
                <Form.Control span="shrink">
                  <Button
                    appearance="outline"
                    intent="danger"
                    label="Delete Account"
                    onClick={confirmAccountDeletion}
                  />
                </Form.Control>
              </Form.Controls>
            </Form.Field>
          </Box.Body>
        </Box>
      </Container>
      <Dialog
        isOpen={isDeleting}
        isClosedOnEscape={false}
        isClosedOnOutsideClick={false}
        showClose={false}
      >
        <Dialog.Body>
          <Pane paddingX={4}>
            <Heading size={1} marginBottom={4}>Deleting Account</Heading>
            <Loader delay={0} />
          </Pane>
        </Dialog.Body>
      </Dialog>
    </Fragment>
  );
};

const validateConfirmPass = (
  value: string,
  values?: PasswordFormState,
  name = 'This Field',
) => {
  if (!value || !values || !values.newPass) {
    return 'Confirmation does not match new password';
  } else {
    if (values.newPass !== value) {
      return 'Confirmation does not match new password';
    }
  }
  return undefined;
};

const PasswordResetForm = () => {
  const [formApi, setFormApi] = React.useState<FormApi<PasswordFormState>>();
  const [isLoading, setIsLoading] = React.useState(false);

  function handlePasswordSubmit(values: PasswordFormState) {
    setIsLoading(true);
    api.user.updatePassword({
      password: values.oldPass,
      newPassword: values.newPass
    }).then(() => {
      formApi?.reset();
      toaster.success({
        text: 'Your Password Was Successfully Updated'
      })
    }).catch((err) => {
      if (err.response.status === 422) {
        formApi?.setError('oldPass', err.response.data.errors?.password);
        formApi?.setError('newPass', err.response.data.errors?.newPassword);
      } else {
        toaster.danger({
          text: 'There was an unknown error when trying to update your password.'
        })
      }
    }).then(() => {
      setIsLoading(false);
    })
  }

  return (
    <Form<PasswordFormState>
      getApi={setFormApi}
      onSubmit={handlePasswordSubmit}
    >
      {({ formState }) => {
        return (
          <fieldset disabled={isLoading}>
            <Form.Field
              label="Old password"
              errorText={formState.errors.oldPass}
            >
              <Form.Control>
                <Form.Password
                  field="oldPass"
                  validate={validate([v.isRequired()])}
                />
              </Form.Control>
            </Form.Field>
            <Form.Field
              label="New Password"
              errorText={formState.errors.newPass}
              description={`
                For security, we require a length of 8 or more characters
              `}
            >
              <Form.Control>
                <Form.Password<PasswordFormState>
                  field="newPass"
                  validate={validate([v.isRequired()])}
                />
              </Form.Control>
            </Form.Field>
            <Form.Field
              label="Confirm Password"
              errorText={formState.errors.confirmPass}
            >
              <Form.Control>
                <Form.Password
                  field="confirmPass"
                  validate={validate([v.isRequired(), validateConfirmPass])}
                />
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <Buttons>
                <Button
                  type="submit"
                  intent="primary"
                  label="Update Password"
                  isLoading={isLoading}
                />
                <AnchorButton
                  appearance="minimal"
                  label="I Forgot My Password" href="/password-reset"
                />
              </Buttons>
            </Form.Field>
          </fieldset>
        );
      }}
    </Form>
  )
}

interface ProfileFormState {
  firstName: string;
  lastName: string;
}

const ProfileForm = () => {
  const dispatch = useThunkDispatch();
  const [formApi, setFormApi] = React.useState<FormApi<ProfileFormState>>();
  const customerProfile = useSelector((state: StoreState) => {
    return state.user.customerProfile;
  });


  React.useEffect(() => {
    formApi?.setValues(convertProfileToFormProps(customerProfile))
  }, [customerProfile]);

  const handleFormSumbit = (values: ProfileFormState) => {
    dispatch(userActions.upsertCustomerProfile({ ...values }))
      .catch(catchUnauthorized)
      .catch(() => { })
  };

  return (
    <Form<ProfileFormState>
      onSubmit={handleFormSumbit}
      getApi={setFormApi}
      initialValues={convertProfileToFormProps(customerProfile)}
    >
      {({ formState }) => {
        return (
          <>
            <Form.Field label="First Name">
              <Form.Controls>
                <Form.Control>
                  <Form.Input
                    field="firstName"
                    validate={validate([v.isRequired()])}
                  />
                </Form.Control>
              </Form.Controls>
            </Form.Field>
            <Form.Field label="Last Name">
              <Form.Controls>
                <Form.Control>
                  <Form.Input
                    field="lastName"
                    validate={validate([v.isRequired()])}
                  />
                </Form.Control>
              </Form.Controls>
            </Form.Field>
            <Form.Field>
              <Form.Controls>
                <Form.Control span="shrink">
                  <Button type="submit" intent="primary" label="Save" />
                </Form.Control>
              </Form.Controls>
            </Form.Field>
          </>
        );
      }}
    </Form>
  );
};

function convertProfileToFormProps(profile: User['customerProfile']): ProfileFormState {
  return {
    firstName: profile?.firstName ?? '',
    lastName: profile?.lastName ?? '',
  };
}
