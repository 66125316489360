import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { store } from './store';
import App from './app';
import { DocHead } from './layout/partials';

const domNode = document.getElementById('root');
const root = createRoot(domNode!);

root.render(
  <Provider store={store}>
    <DocHead
      defaultTitle="Welcome"
      defaultDescription="A resource to help design, build, and maintain your Adair Home"
      titleTemplate={"{{title}} | MyAdair"}
    />
    <App />
  </Provider>
, );
