import { useLocation } from "@reach/router";
import { useMemo } from "react";

export function useQueryParams() {
  const location = useLocation();
  const params = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search])

  return params;
}