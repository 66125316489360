import * as React from 'react';
import cn from 'classnames';
import Fuse from 'fuse.js';
import { isUndefined, isString } from 'lodash-es';
import { PickByValue } from 'utility-types';

import {
  IProps,
  isFunction,
  safeInvoke,
  keys,
  IIntentProps,
  IControlProps,
  isDefined,
  ControlAppearanceType,
  Intent,
  ControlShapeType,
  ControlDensityType,
  ControlSizeType,
  Pane,
  Popover,
  OptionList, OptionItem, IOptionRendererProps, selectClasses,
  IPopoverProps,
  Input,
} from '@adair/core-ui';
import { IconCaretDown } from '@adair/core-ui/lib/icons';

export interface ISearchInputProps<T>
  extends IProps,
  IIntentProps,
  IControlProps,
  Pick<IPopoverProps, 'boundary'> {
  /** Inputs don't have children */
  children?: never;

  /** Unique Identifier for the input */
  field: string;

  /**
   * The property on the value which is the unique identifier
   */
  idProp: keyof PickByValue<T, string>;

  /**
   * Whether the component is non-interactive.
   * @default false
   */
  isDisabled?: boolean;

  /** Controlled value of the `<input>` element. */
  inputValue?: string;

  /**
   * Callback invoked when new tags are added by the user pressing `enter` on the input.
   * This should return a new value derived from the new value or return false to
   * cancel the addition.
   */
  onAdd?: (value: string, index: number) => T | false;

  /** callback invoked when the input loses focus */
  onBlur?: (event: React.FocusEvent) => void;

  /**
   * Callback invoked when the value of `<input>` element is changed.
   */
  onInputChange?: React.FormEventHandler<HTMLInputElement>;

  /**
   * Callback invoked when the user depresses a keyboard key.
   * Receives the event and the index of the active tag (or `undefined` if
   * focused in the input).
   */
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>, index?: number) => void;

  /**
   * Callback invoked when the user releases a keyboard key.
   * Receives the event and the index of the active tag (or `undefined` if
   * focused in the input).
   */
  onKeyUp?: (event: React.KeyboardEvent<HTMLElement>, index?: number) => void;

  /** callback invoked when the input is focused */
  onFocus?: (event: React.FocusEvent) => void;

  /**
   * Callback invoked when the user removes a value.
   * Receives value and index of removed tag.
   */
  onRemove?: (value: T, index: number) => void;

  /**
   * Callback invoked when a values are added, selected, or removed.
   */
  onUpdate?: (values: T | null) => void;

  /**
   * Values that will be be suggested the user to select.
   */
  options: T[];

  /**
   * Placeholder shows when values array is empty.
   */
  placeholder?: string;

  /**
   * Tells the component how to render your label. You can either provide a property
   * from the object to render, or a function that returns a value
   */
  renderLabel: keyof T | ((value: T) => string);

  /**
   * Controlled value
   */
  value: T | null;
}

export const SearchInput = <T extends {}>(props: ISearchInputProps<T>) => {
  const [inputValue, setInputValue] = React.useState('');
  const [showSuggest, setShowSuggest] = React.useState(false);
  const [fuse, setFuse] = React.useState<Fuse<T, Fuse.FuseOptions<T> & { includeMatches: false, includeScore: false }>>();
  const [targetRef, setTargetRef] = React.useState<HTMLElement | null>(null);
  const [popupRef, setPopupRef] = React.useState<HTMLElement | null>(null);
  const [width, setWidth] = React.useState<number>(0);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const optionListRef = React.useRef<OptionList<T>>(null);


  const derivedInputValue = React.useMemo(() => {
    return isUndefined(props.inputValue) ? inputValue : props.inputValue;
  }, [props.inputValue, inputValue]);


  //
  // Lifecycle
  //

  React.useEffect(() => {
    if (typeof props.renderLabel === 'string') {
      setFuse(new Fuse(props.options, { keys: [props.renderLabel], includeMatches: false, includeScore: false }));
    }
  }, [props.options, props.renderLabel]);

  //
  // Actions
  //

  const clearValues = () => {
    safeInvoke(props.onUpdate, null);
    inputRef.current?.focus();
  };

  //
  // Handlers
  //

  const handleInputFocus = (event: React.FocusEvent) => {
    safeInvoke(props.onFocus, event);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!!optionListRef.current) {
      optionListRef.current.setFocusOption(null);
    }

    setInputValue(event.currentTarget.value);
    setShowSuggest(!!event.currentTarget.value);

    safeInvoke(props.onInputChange, event);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const optionList = optionListRef.current;

    // Enter: Add Tag
    if (event.which === keys.ENTER) {
      if (!!optionList && !!optionList.state.focusedOption) {
        optionList.handleKeyDown(event);
      } else {
        setShowSuggest(true);
      }
    }
    // Down Arrow: open suggest, pass to optionList
    else if (event.which === keys.ARROW_DOWN) {
      setShowSuggest(true);
      if (optionList) {
        optionList.handleKeyDown(event);
      }
    }
    // Up Arrow: pass to optionList
    else if (event.which === keys.ARROW_UP) {
      if (optionList) {
        if (optionList.getFocusedIndex() === 0) {
          event.preventDefault();
          optionList.setFocusOption(null);
        } else {
          optionList.handleKeyDown(event);
        }
      }
    }
    // Escape: close
    else if (event.which === keys.ESCAPE) {
      setShowSuggest(false);
    }

    // invokeKeyPressCallback('onKeyDown', event, activeIndexToEmit);
  };

  const handleOptionSelect = (
    option: T,
    e?: React.SyntheticEvent<HTMLElement>,
  ) => {

    inputRef.current?.focus();
    setInputValue('');
    setShowSuggest(false);
    safeInvoke(props.onUpdate, option);
  };


  const handleDropdownClick = () => {
    setShowSuggest(true);
  };

  const handlePopoverOpening = () => {
    if (!!targetRef) {
      setWidth(targetRef.getBoundingClientRect().width);
    }
  };

  //
  // Render
  //

  const renderLabel = (value: T) => {
    if (isFunction(props.renderLabel)) {
      return props.renderLabel(value);
    }

    const rtn = value[props.renderLabel];
    if (isString(rtn)) return rtn;

    return '';
  };

  const renderOption = (option: T, itemProps: IOptionRendererProps) => {
    return (
      <OptionItem
        key={itemProps.id}
        id={itemProps.id}
        isActive={itemProps.modifiers.active}
        isDisabled={itemProps.modifiers.disabled}
        isFocused={itemProps.modifiers.focused}
        onClick={itemProps.handleClick}
        onMouseOver={itemProps.handleMouseEnter}
      >
        {isFunction(props.renderLabel)
          ? props.renderLabel(option)
          : option[props.renderLabel] as string
        } 
      </OptionItem>
    );
  };

  const renderNoOptions = () => {
    return (
      <Pane
        padding={[4]}
        style={{ minWidth: width }}
        className="t--center"
      >
        No Matches
      </Pane>
    );
  };

  const RenderSuggest = (suggestList: T[] = []) => {
    return (
      <OptionList<T>
        id={`${props.field}__list`}
        options={suggestList}
        renderOption={renderOption}
        activeOptions={props.value ? [props.value] : []}
        onOptionSelect={handleOptionSelect}
        ref={optionListRef}
        optionIdProp={props.idProp}
        noResults={renderNoOptions()}
        style={{ minWidth: width }}
      />
    );
  };

  function maybeRenderDropdown() {
    if (isDefined(props.value)) return null;
    return (
      <span
        className={cn(selectClasses.ACTION)}
        onClick={handleDropdownClick}
      >
        <IconCaretDown />
      </span>
    )
  }

  const filteredSuggest = isDefined(fuse) && !!derivedInputValue
    ? fuse.search(derivedInputValue)
    : props.options.slice(0, 10);

  return (
    <Popover
      autoFocus={false}
      isOpen={showSuggest}
      enforceFocus={false}
      className="select__options"
      content={RenderSuggest(filteredSuggest)}
      position="bottom"
      isMinimal
      isDisabled={props.isDisabled}
      boundary={props.boundary}
      onOpening={handlePopoverOpening}
      onResize={handlePopoverOpening}
      getReferenceRef={setTargetRef}
      getPopupRef={setPopupRef}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        }
      ]}
    >
      <Input
        field="impersonate"
        value={isDefined(props.value) ? renderLabel(props.value) : derivedInputValue}
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        // onKeyUp={handleInputKeyUp}
        placeholder={props.placeholder}
        className={props.className}
        disabled={props.isDisabled}
        isClearable
        readOnly={isDefined(props.value)}
        onClear={clearValues}
        ref={inputRef}
        after={maybeRenderDropdown()}
      />
    </Popover>
  );
};

SearchInput.defaultProps = {
  appearance: ControlAppearanceType.OUTLINE,
  boundary: 'viewport',
  density: ControlDensityType.NORMAL,
  intent: Intent.NONE,
  isDisabled: false,
  shape: ControlShapeType.ROUNDED,
  size: ControlSizeType.MEDIUM,
};
