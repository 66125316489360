import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { Link, navigate, useLocation } from '@reach/router';
import cn from 'classnames';
import {
  Container,
  Message,
  Form,
  Buttons, Button,
  Heading, Text,
  marginClass,
  validate, validators as v,
  isDefined,
} from '@adair/core-ui';
import {
  IconMail,
  IconArrowRight,
  IconSocialGoogle
} from '@adair/core-ui/lib/icons';
import { queryParamsToObject } from '@adair/core-client-utilities/lib/location';

import { sessionActions } from '../../../store/session';
import { useThunkDispatch } from '../../../store';
import { SplitTemplate } from '../../templates';
import { SEO } from '../../partials';
import { useNextPage } from '../../../hooks';


interface SignUpFormState {
  name: string;
  username: string;
  password: string;
}

export const SignUpPage: React.FC = (props) => {
  const dispatch = useThunkDispatch();
  const location = useLocation();
  const nextPage = useNextPage(true);
  const [formError, updateFormError] = useState<{ message: string }>({
    message: '',
  });
  const [useLocalForm, setUseLocalForm] = useState(false);
  const { verify, email } = queryParamsToObject(location.search);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const failMessage = params.get('fail_message')
    if (failMessage) {
      params.delete('fail_message')
      window.history.replaceState(
        null,
        document.title,
        `${location.protocol}${location.pathname}?${params.toString()}`
      );
      updateFormError({ message: failMessage });
    }
  }, []);

  function handleFormSubmit (values: SignUpFormState) {
    updateFormError({ message: '' });

    dispatch(sessionActions.register.init.action({
      username: values.username,
    }))
      .then((resp) => {
        navigate(`/sign-up/verify?email=${encodeURIComponent(values.username)}`);
      })
      .catch((error: AxiosError) => {
        const errorCode = error.response ? error.response.status : 500;
        if (errorCode === 422) {
          updateFormError({
            message: error.response?.data.message,
          });
        } else {
          updateFormError({
            message: `There was an error submitting your email. Please try again in a
          few minutes or contact us if the issue persists.`,
          });
        }
      });
  };

  function handleEmailClick() {
    if (isDefined(verify) && isDefined(email)) {
      navigate(`/sign-up/verify?email=${email}&verify=${verify}`)
    } else {
      setUseLocalForm(true)
    }
  }

  function loginViaGoogle() {
    dispatch(sessionActions.logIn.oauth.action({
      provider: 'google',
      successRedirect: nextPage ? nextPage : verify ? `/verify/${verify}` : '/',
      failureRedirect: '/sign-up',
    }))
  }

  function renderOauthOptions() {
    return (
      <div>
        <Buttons density="relaxed">
          <Button
            isFullWidth
            label="Sign up with Google"
            intent="primary"
            before={<IconSocialGoogle />}
            onClick={loginViaGoogle}
          />
          {!useLocalForm &&
            <Button
              isFullWidth
              label="Sign up with Email"
              appearance="outline"
              before={<IconMail />}
              onClick={handleEmailClick}
            />
          }
        </Buttons>
        {useLocalForm && renderLocalSignupForm()}
      </div>
    )
  }

  function renderLocalSignupForm() {
    return (
      <div>
        <Form
          onSubmit={handleFormSubmit}
        >
          {({ formState }) => (
            <div className={marginClass([2, null, null])}>
              <Form.Field errorText={formState.errors.username}>
                <Form.Controls isCombined>
                  <Form.Control>
                    <Form.Input
                      field="username"
                      placeholder="Email"
                      validate={validate([v.isRequired(), v.isEmail()])}
                      density="relaxed"
                    />
                  </Form.Control>
                  <Form.Control span="shrink">
                    <Button
                      type="submit"
                      intent="primary"
                      appearance="fill"
                      after={<IconArrowRight />}
                      label="Continue"
                      density="relaxed"
                    />
                  </Form.Control>
                </Form.Controls>
              </Form.Field>
            </div>
          )}
        </Form>
      </div>
    )
  }

  return (
    <SplitTemplate>
      <SEO title="Sign Up" />
      <div className="main-content__interior">
        <Container maxWidth="xs">
          <Heading size={4}>Sign up for myAdair</Heading>
          <p className={cn(marginClass([1, null, 6]), 'c--gray500')}>
            A free tool to help build your dream home.
          </p>
          {formError.message !== '' &&
            <Message intent="danger" appearance="fill" text={formError.message} className={marginClass([null, null, 6])} showIcon />
          }
          {renderOauthOptions()}
          <Text margin={[4, null, null]} align="center" color="gray600">
            Already have an account? &nbsp;
            <strong><Link to={`/login/${location.search}`}>Sign in.</Link></strong>
          </Text>
        </Container>
      </div>
    </SplitTemplate>
  )
}
