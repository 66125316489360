import React from 'react';
import { uniqBy } from 'lodash-es';
import {
  Container, Columns, Column,
  Pane,
  Heading,
  Hr,
  isDefined,
  Tag,
} from '@adair/core-ui';
import { HomeSite } from '@adair/core-client-utilities/lib/home-site';

import { SEO } from '../../partials';
import { getHomeSiteName } from './home-site-index';

interface HomeDashPageProps {
  children?: never;
  homeSite: HomeSite;
}

export const HomeContactsPage: React.FC<HomeDashPageProps> = (props) => {
  const employees = uniqBy([
    // props.homeSite.administrativeProjectCoordinator,
    // props.homeSite.drawAdmin,
    props.homeSite.primaryContact,
    props.homeSite.homeOwnershipCounselor,
    props.homeSite.regionalSalesManager,
    props.homeSite.preConstructionAdmin,
    props.homeSite.seniorPreConstructionAdmin,
    props.homeSite.constructionSuperintendent,
    props.homeSite.regionalConstructionManager,
  ].filter(e => isDefined(e)), 'id')

  return (
    <Pane as="section" padding={[8, null]}>
      <SEO title={`${getHomeSiteName(props.homeSite)} | Contacts`} />
      <Container margin={[null, null, 8]} maxWidth="lg">
        <Columns className="max--md">
          <Column>
            <Heading size={4} margin={[null, null, 2]}>Your Pro Team</Heading>
          </Column>
        </Columns>
      </Container>

      <Container maxWidth="lg">
        <Columns>
          {employees.map((employee) => {
            const isPrimary = props.homeSite?.primaryContact && employee!.id === props.homeSite?.primaryContact.id
            return (
              <Column key={employee!.id} span={{ xs: 12, md: 6, lg: 6 }}>
                <Pane
                  bgColor="gray100"
                  border="small"
                  borderRadius="medium"
                  borderColor={isPrimary ? 'primary' : 'gray100'}
                >
                  <Pane padding={[6, 6, 4]}>
                    <Columns>
                      {/* <Avatar size="sm" shape="circular" /> */}
                      <Column>
                        <Pane>
                          <Heading size={2}>{employee?.name}</Heading>
                          <div className="c--gray600 f--1">{employee?.title}</div>
                        </Pane>
                      </Column>
                      <Column span="shrink" align="top">
                        {isPrimary && <Tag size="small" intent="primary">Primary Contact</Tag>}
                      </Column>
                    </Columns>
                  </Pane>
                  <Hr />
                  <Pane padding={[4, 6, 6]}>
                    <ul className="list">
                      {employee?.workPhone && <li><strong>o.</strong> {employee.workPhone}</li>}
                      {employee?.cellPhone && <li><strong>c.</strong> {employee.cellPhone}</li>}
                      {employee?.email && <li><strong>e.</strong> <a href={`mailto:${employee.email}`}>{employee.email}</a></li>}
                    </ul>
                  </Pane>
                </Pane>
              </Column>
            )
          })}
        </Columns>
      </Container>
    </Pane>
  );
};
