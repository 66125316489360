import React, { useState } from 'react';
import { Router } from '@reach/router';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { AlertContainer, ToasterContainer, Loader, Pane } from '@adair/core-ui';

import { Route } from './components';
import { useThunkDispatch, StoreState } from './store';
import { sessionActions } from './store/session'
import {
  AccountIndex,
  Error500Page,
  Error404Page,
  HomePage,
  HomeQuotePage,
  LoginPage,
  RegisterPage,
  SignUpPage,
  VerifyPage,
  HomesSection,
  HomeSiteIndexPage,
  FavoritesIndexPage,
  PasswordResetPage,
  ResourcesPage,
  VerifyLocalPage,
} from './layout/pages'
import { BannerContainer } from './components/banner';
import { AdminHeader, Header } from './layout/partials';

// Load styles
import './app.scss';
import { reporter } from './util/reporter';

const App: React.FC = () => {
  const dispatch = useThunkDispatch();
  const [isInitialized, setIsInitialized] = useState(false);
  const [siteError, setSiteError] = useState<false | number>(false);
  const showHeader = useSelector((state: StoreState) => {
    return state.ui.showHeader;
  });
  const isImpersonation = useSelector((state: StoreState) => {
    return state.user.impersonate !== null;
  })

  React.useEffect(() => {
    // get the user session
    dispatch(sessionActions.fetch.action())
      .catch((e) => {
        if (!e.response) {
          reporter.notify({
            ...e,
            message: 'Unable to connect to core',
          })
          setSiteError(500);
        }
        return;
      })
      .then(() => {
        setIsInitialized(true);
      })
  }, [dispatch]);

  if (!isInitialized) return <Pane padding={[8, null]}><Loader isLoading /></Pane>;
  if (siteError === 500) return <Error500Page />;

  return (
    <div className="App">
      <AdminHeader />
      {showHeader && <Header />}
      <BannerContainer />
      <Router primary={false} className={cn('page', { 'preview-mode': isImpersonation })}>
        <Route path="/" component={HomePage} isPrivate />
        <Route path="/login" component={LoginPage} />
        <Route path="/sign-up" component={SignUpPage} />
        <Route path="/sign-up/verify" component={VerifyLocalPage} />
        <Route path="/password-reset" component={PasswordResetPage} />
        <Route path="/verify/:token" component={VerifyPage} isPrivate/>
        <Route path="/welcome/profile" component={RegisterPage} isPrivate/>
        <Route path="/favorites/*" component={FavoritesIndexPage} isPrivate />
        <Route path="/account/*" component={AccountIndex} isPrivate />
        <Route path="/homes" component={HomeSiteIndexPage} isPrivate />
        <Route path="/homes/:id/*homePath" component={HomesSection} isPrivate />
        <Route path="/home-quotes" component={HomeQuotePage} isPrivate />
        <Route path="/resources" component={ResourcesPage} isPrivate />
        <Route component={Error404Page} default />
      </Router>
      <AlertContainer />
      <ToasterContainer />
    </div>
  );
}

export default App;
