import { actionCreatorFactory } from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { includes, isNull } from 'lodash-es';
import { User, FetchUserProps } from '@adair/core-client-utilities/lib/user';
import { CustomerProfile } from '@adair/core-client-utilities/lib/customer-profile';
import { toaster } from '@adair/core-ui';

import { StoreState } from '../types';
import { AxiosResponse } from 'axios';
import { api, baseApi } from '../../util/api';

const PREFIX = 'user';

const actionCreator = actionCreatorFactory(PREFIX);
const createAsyncWorker = asyncFactory<StoreState>(actionCreator);

type UpsertCustomerProfileProps = Pick<CustomerProfile, 'firstName' | 'lastName'> &
  Partial<Pick<CustomerProfile, 'termsAccepted'>>;
type UpsertCustomerProfileSettingsProps = Partial<CustomerProfile['settings']>;
type VerifyUserProps = { token: string }

const fetch = createAsyncWorker<FetchUserProps, User>(
  'fetchUser',
  async (props, dispatch) => {
    const { data } = await api.user.fetch(props).request
    return data;
  },
);

const deleteUser = createAsyncWorker<void, void>(
  'deleteUser',
  async (props, dispatch, getState) => {
    const state = getState();

    if (!state.user.id) {
      throw new Error('Unable to delete: No Authenticated User');
    }

    await api.user.delete({ id: state.user.id }).request;

    toaster.success({
      text: 'Your account was successfully deleted',
      position: 'top',
    });

    return;
  },
);

const upsertCustomerProfile = createAsyncWorker<UpsertCustomerProfileProps, CustomerProfile>(
  'upsertCustomerProfile',
  async (props, dispatch, getState) => {
    let resp: AxiosResponse<CustomerProfile>;
    const state = getState();

    if (!state.user.customerProfile) {
      resp = await api.customerProfile.create({ ...props, userId: state.user.id }).request;
    } else {
      resp = await api.customerProfile.update({ id: state.user.customerProfile.id, ...props }).request;
    }

    toaster.success({ text: 'Profile Saved Successfully', position: "bottom-right" });

    return resp.data;
  },
);

const updateCustomerProfileSettings = createAsyncWorker<UpsertCustomerProfileSettingsProps, CustomerProfile>(
  'updateCustomerProfileSettings',
  async (props, dispatch, getState) => {
    const state = getState();
    try {
      if (!state.user.customerProfile)
        throw new Error('Unable to Update Settings: No Profile')

      const resp = await api.customerProfile.updateSettings({ ...props, id: state.user.customerProfile.id }).request;

      toaster.success({ text: 'Settings Saved Successfully', position: "bottom-right" });
      return resp.data;
    } catch (err) {
      toaster.danger({
        heading: 'Unable to Save',
        text: 'There was an error while saving your settings',
        position: "bottom-right"
      });
      throw err;
    }
  },
);

const verifyHomeowner = createAsyncWorker<VerifyUserProps, void>('VERIFY', (props, dispatch) => {
  return api.homeowner.verify(props).request;
})

const impersonate = createAsyncWorker<string | null, string | null>('IMPERSONATE', async (id, dispatch, getState) => {
  // this is not proper, but we're hijacking the reducer to inject the appropriate
  // header on the api instance
  const state = getState();

  if (isNull(id)) {
    delete baseApi.axios.defaults.headers.common['X-Impersonate'];
    await dispatch(fetch.action({ id: state.session.userId }));
    return null;
  }
  
  if (!includes(state.user.roles, 'employee')) return null;

  baseApi.axios.defaults.headers.common['X-Impersonate'] = id;
  await dispatch(fetch.action({ id }));

  return id;
});

export const userActions = {
  fetch,
  delete: deleteUser,
  upsertCustomerProfile,
  updateCustomerProfileSettings,
  verifyHomeowner,
  impersonate
};
