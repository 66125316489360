import React from 'react';
import { Router } from '@reach/router';

import { BaseTemplate } from '../../templates';
import { AccountPage } from './account';
import { SettingsPage } from './settings';
import { Route } from '../../../components';


export const AccountIndex: React.FunctionComponent = (props) => {

  return (
    <BaseTemplate
      navTitle="My Account"
      navLinks={[
        { label: 'Account', url: '/account' },
        { label: 'Settings', url: '/account/settings' },
      ]}
    >
      <Router primary={false} basepath="/account">
        <Route path="/" component={AccountPage} />
        <Route path="/settings" component={SettingsPage} />
      </Router>
    </BaseTemplate>
  );
};
