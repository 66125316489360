import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from '../../store';

interface DocHeadProps {
  /** A default page title if one is not set */
  defaultTitle?: string;
  /** A default page description if one is not set */
  defaultDescription?: string;
  /**
   * The template for inserting the title into. any instance of '{{title}}' will
   * be replaced with the page title
   * @default "{{title}}"
   */
  titleTemplate?: string;
}

/**
 * DocumentHead is meant to be used once in the app. It does not render anything,
 * but rather manages the document title and (potentially in the future) meta data
 */

export const DocHead: React.FC<DocHeadProps> = (props) => {
  const pageTitle = useSelector((state: StoreState) => state.ui.pageTitle);
  const pageDescription = useSelector((state: StoreState) => state.ui.pageDescription);

  useEffect(() => {
    document.title = pageTitle && props.titleTemplate
      ? props.titleTemplate.replace('{{title}}', pageTitle)
      : props.defaultTitle
        ? props.defaultTitle
        : '';
  }, [pageTitle, props.defaultTitle, props.titleTemplate]);
  
  useEffect(() => {
    const descriptionValue = pageDescription
      ? pageDescription
      : props.defaultDescription
        ? props.defaultDescription
        : ''
    const meta = document.querySelector('meta[name="description"]');
    if (meta) {
      meta.setAttribute('content', descriptionValue);
    }
  }, [pageDescription, props.defaultDescription]);
  
  return null;
}

DocHead.defaultProps = {
  titleTemplate: '{{title}}',
  defaultTitle: '',
  defaultDescription: '',
}
