import React, { useMemo } from 'react';
import cn from 'classnames';
import { Link, navigate } from '@reach/router';
import { format as formatDate } from 'date-fns';
import { find } from 'lodash-es';
import { HomeSite } from '@adair/core-client-utilities/lib/home-site';
import {
  Container,
  Box,
  Columns, Column,
  marginClass,
  sortObjects,
  Heading, Text,
  StaticMap, MapRatioType,
  Pane,
  Hr,
  Buttons, Button,
  toaster,
  isUSStateId, getState,
  isDefined
} from '@adair/core-ui';
import { TimelineSchedule } from '@adair/core-client-utilities/lib/timeline';
import { IconArrowRight } from '@adair/core-ui/lib/icons';

import { api } from '../../../util/api';
import { Error404Page } from '../error/error-404';
import { LinkButton } from '../../../components';
import { DocumentTile, TimelineBar, SEO } from '../../partials';
import { getHomeSiteName, getLocationStage } from './home-site-index';

interface HomeDashPageProps {
  children?: never;
  homeSite?: HomeSite;
  phases?: TimelineSchedule['phases'];
}

export const HomeDashPage: React.FC<HomeDashPageProps> = (props) => {

  const { currentPhase, nextMilestone } = useMemo(() => {
    const current = find(props.phases, ['isCurrent', true]);
    return {
      currentPhase: current,
      nextMilestone: current ? find(current.milestones, ['completed', null]) : undefined,
    };
  }, [props.phases]);


  function downloadDoc(id: string) {
    api.document.download(id)
      .catch(() => {
        toaster.danger({
          text: 'There was an error trying to download your document'
        })
      })
  }

  if (!props.homeSite) return <Error404Page />;

  const recentDocs = sortObjects(props.homeSite.documents, ['contentModifiedDate']).slice(0, 4);
  const locationStage = getLocationStage(props.homeSite);
  let locationUrl = [
    props.homeSite.siteAddress,
    props.homeSite.siteCity,
    props.homeSite.siteCounty,
    props.homeSite.siteState,
    props.homeSite.siteZipcode,
    'usa',
  ].filter(isDefined).join(',')

  return (
    <Pane as="section" padding={[8, null]}>
      <SEO title={getHomeSiteName(props.homeSite)} />
      <Container maxWidth="lg">
        <Columns align="middle" justify="center">
          {locationStage !== 'none' &&
            <Column span="shrink">
              <StaticMap
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY!}
                center={locationUrl}
                zoom={undefined}
                ratio={MapRatioType.SQUARE}
                width={200}
                mapType="terrain"
                className={'rounded--full'}
                style={{ width: 200 }}
              >
                {props.homeSite.siteCity &&
                  <StaticMap.Marker
                    location={locationUrl}
                  />
                }
              </StaticMap>
            </Column>
          }
          <Column key={props.homeSite.id} span={{ xs: 12, md: 'auto' }} className="t--center t--left::md">
            <Heading size={5} margin={[null, null, 1]}>
              {getHomeSiteName(props.homeSite)}
            </Heading>
            {(locationStage !== 'none' && locationStage !== 'state') &&
              <div className="f--2 c--gray500">
                {locationStage === 'complete'
                  ? `${props.homeSite.siteCity}, ${props.homeSite.siteState}`
                  : `${isUSStateId(props.homeSite.siteState) ? getState(props.homeSite.siteState).label : props.homeSite.siteState}`
                }
              </div>
            }
            {/* <Columns className={marginClass([6, null, null])} justify={{ xs: 'center', md: 'start' }}>
              <Column span="shrink">
                <Heading size={1}>Plan Model</Heading>
                <span className={'c--gray400'}>TBD</span>
              </Column>
              <Column span="shrink">
                <Heading size={1}>Year Built</Heading>
                <span className={'c--gray400'}>In Progress</span>
              </Column>
            </Columns> */}
            <Pane margin={[4, null, null]}>
              <Heading as="span" size={1} margin={[null, 2, null, null]}>Current Phase</Heading>
              <br className="d--none::md" />
              <Link to={`/homes/${props.homeSite.id}/timeline/${currentPhase?.slug}`}>
                <span>{currentPhase?.label ?? 'unknown'}</span>
              </Link>
            </Pane>
            <div >
              <Heading as="span" size={1} margin={[null, 2, null, null]}>Next Milestone</Heading>
              <br className="d--none::md" />
              <span>{nextMilestone?.label ?? 'N/A'}</span>
            </div>
          </Column>
        </Columns>
      </Container>

      <Hr spacing={8} />

      <Container maxWidth="lg">
        <TimelineBar
          phases={props.phases ?? []}
          onClick={(phase) => { navigate(`/homes/${props.homeSite!.id}/timeline/${phase.slug}`) }}
        />
        {/* <Columns className={marginClass([6, null, null])} justify="center">
          <Column span="shrink">
            <Heading as="span" size={1} margin={[null, 2, null, null]}>Current Phase</Heading>
            <br className="d--none::md" />
            <Link to={`/homes/${props.homeSite.id}/timeline/${currentPhase?.slug}`}>
              <span>{currentPhase?.label ?? 'unknown'}</span>
            </Link>
          </Column>
          <Column span="shrink">
            <Heading as="span" size={1} margin={[null, 2, null, null]}>Next Milestone</Heading>
            <br className="d--none::md" />
            <span>{nextMilestone?.label ?? 'N/A'}</span>
          </Column>
          <Column span="shrink" className={cn('d--none', 'd--block::sm')}>
            <Heading as="span" size={1} margin={[null, 2, null, null]}>Last Updated</Heading>
            <br className="d--none::md" />
            <span>{formatDate(props.homeSite.updatedAt, 'M/dd/yyyy') ?? 'unknown'}</span>
          </Column>
        </Columns> */}
      </Container>

      <Hr spacing={8} />

      <Container maxWidth="lg" margin={[8, null, 6]}>
        <Columns align="middle">
          <Column><Heading size={2}>Recently Added Documents</Heading></Column>
          <Column span="shrink">
            <LinkButton
              href={`/homes/${props.homeSite.id}/documents`}
              appearance="minimal"
              density="compact"
              label="View All"
              after={<IconArrowRight />}
            />
          </Column>
        </Columns>

        <Box density="relaxed">
          <Box.Body>
            <Columns>
              {recentDocs.length > 0
                ? recentDocs.map((doc) => {
                  return (
                    <Column key={doc.id} span={{ xs: 12, md: 6 }}>
                      <DocumentTile
                        bgColor="gray100"
                        document={doc}
                      />
                    </Column>
                  )
                })
                : (
                  <Column>
                    <Heading size={2}>No documents yet...</Heading>
                    <Text>Documents are added as your project progresses, so check back.</Text>
                  </Column>
                )
              }
            </Columns>
          </Box.Body>
        </Box>
      </Container>
    </Pane>
  );
};
