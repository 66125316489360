import React from 'react';
import cn from 'classnames';
import { Plan } from '@adair/core-client-utilities/lib/plan';
import {
  Box,
  Columns, Column,
  Tooltip,
  Close,
  Buttons, Button,
  Heading,
  paddingClass,
  safeInvoke,
  toaster,
  IProps,
  Pane,
  Tag,
  marginClass
} from "@adair/core-ui";
import { IconArrowRight, IconInfoCircle } from '@adair/core-ui/lib/icons';

import { api } from '../../../util/api';

export interface PlanCardProps extends IProps {
  plan: Plan;
  onUnFavoriteStart?: () => void;
  onUnFavoriteSuccess?: () => void;
  onUnFavoriteFailure?: () => void;
}

export const PlanCard: React.FC<PlanCardProps> = (props) => {

  function unfavoritePlan(e?: React.SyntheticEvent) {
    e?.preventDefault();
    safeInvoke(props.onUnFavoriteStart);

    api.favorite.unfavoritePlan(props.plan.id).request
      .then(() => {
        safeInvoke(props.onUnFavoriteSuccess);
      })
      .catch((err) => {
        toaster.danger({ text: 'There was an Uknown error removing this plan' })
        safeInvoke(props.onUnFavoriteFailure);
      })
  }

  return (
    <a
      href={`${process.env.REACT_APP_WEB_URL}/homes/plan/${props.plan.slug}`}
      className={cn('plan-card', props.className)}
      style={props.style}
      target="_blank"
    >
      <div
        className="plan-card__inner"
        style={{
          backgroundImage: `url(${props.plan.heroImages?.[0]?.previewUrl})`,
        }}
      >
        <div className="plan-card__overlay d--flex flex--column justify--between">
          <div className="unfavorite">
            <Tooltip content="Remove from Favorites" referenceTagName="span" hoverOpenDelay={500}>
              <Close size="large" onClick={unfavoritePlan} className="-light" />
            </Tooltip>
          </div>
          <Columns className={paddingClass([6, 4, null, 8])}>
            <Column>
            <Heading size={"sm"} color="yellow" className="t--uc">Home Plan</Heading>
              <Heading size={4} color="white"><small>the</small> <span className="t--uc">{props.plan.name}</span></Heading>
            </Column>
          </Columns>
          <Buttons flexJustify="center" margin={[2, null, null]}>
            <Button
              label="View"
              intent="warning"
              after={<IconArrowRight />}
            />
          </Buttons>

          <Pane padding={[4]}>
            {props.plan.isArchived &&
              <Tooltip
                content="We no longer offer this as a base plan. It may be removed in the future"
                referenceTagName="span"
                // intent="warning"
              >
                <Tag
                  appearance="fill"
                  intent="warning"
                >
                  <IconInfoCircle className={marginClass([null, 2, null, null])} />
                  No Longer Available
                </Tag>
              </Tooltip>
            }
          </Pane>
        </div>
      </div>
      <Box.Footer padding={[4, 6]}>
        <Columns justify="center">
          <Column span="shrink">
            <Heading size={3} as="span" color="green" margin={[null, 2, null, null]}>{props.plan.beds}</Heading>
            <Heading size={1} as="span" color="gray400" className="t--uc">Beds</Heading>
          </Column>
          <Column span="shrink">
            <Heading size={3} as="span" color="green" margin={[null, 2, null, null]}>{props.plan.baths}</Heading>
            <Heading size={1} as="span" color="gray400" className="t--uc">Baths</Heading>
          </Column>
          <Column span="shrink">
            <Heading size={3} as="span" color="green" margin={[null, 2, null, null]}>{props.plan.sqft}</Heading>
            <Heading size={1} as="span" color="gray400" className="t--uc">SF</Heading>
          </Column>
        </Columns>
      </Box.Footer>
    </a>
  )
}
