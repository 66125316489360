import { actionCreatorFactory } from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { Session } from '@adair/core-client-utilities/lib/session';

import { api } from '../../util/api';
import { StoreState } from '../index';
import { config } from '../../config';
import { userActions } from '../user';
import { isDefined } from '@adair/core-ui';

const PREFIX = 'session';

//
// Definitions
//

interface LoginParams {
  username: string;
  password: string;
  rememberMe: boolean;
}

interface SignUpParams {
  password: string;
  token: string;
}

interface OAuthParams {
  provider: 'google' | 'facebook';
  successRedirect?: string;
  failureRedirect?: string;
}

interface SessionParams {
  sessionId?: string;
}

function makeAbsoluteUrl (url: string) {
  let formattedRedirect = url;

  if (url.startsWith('/')) {
    formattedRedirect = `${config.url}${url}`;
  }
  return formattedRedirect;
}


// 
// Actions
// 

const actionCreator = actionCreatorFactory(PREFIX);
const createAsyncWorker = asyncFactory<StoreState>(actionCreator);

const localLogIn = createAsyncWorker<LoginParams, Session>(
  'LOGIN_LOCAL', 
  async (params, dispatch) => {
    const { username, password } = params;
    const resp = await api.session.login({ username, password }).request;

    if (resp.data.userId) {
      await dispatch(userActions.fetch.action({ id: resp.data.userId }));
    }

    return resp.data;
  },
);

const oauthLogIn = createAsyncWorker<OAuthParams, void>(
  'LOGIN_OAUTH', 
  async (params, dispatch) => {
    const { successRedirect, failureRedirect } = params;

    const url = new URL(`${config.api.baseUrl}/api/auth/${params.provider}`);
    
    if (successRedirect) {
      url.searchParams.append('successRedirect', makeAbsoluteUrl(successRedirect));
    }
    if (failureRedirect) {
      url.searchParams.append('failureRedirect', makeAbsoluteUrl(failureRedirect));
    }

    window.location.href = url.toString();
    
    return;
  },
);

const logOut = createAsyncWorker<{}, boolean>(
  'LOGOUT', async (params, dispatch) => {
    await api.session.logout().request;

    return true;
  },
);

const initLocalRegistration = createAsyncWorker<{username: string}, void>('INIT_SIGN_UP', async (params, dispatch) => {
  const { username } = params;
  return api.session.initLocalRegistration({ username }).request;
});

const registerLocal = createAsyncWorker<SignUpParams, Session>('SIGN_UP', async (params, dispatch) => {
  const { password, token } = params;
  const { data: session } = await api.session.registerLocal({ password, token }).request;

  if (session.userId) {
    await dispatch(userActions.fetch.action({ id: session.userId }));
  }

  return session;
});

const fetch = createAsyncWorker<SessionParams | undefined, Session>(
  'FETCH',
  async (params, dispatch, getState) => {
    const state = getState();
    const resp = await api.session.fetch(params?.sessionId).request
    
    const session = resp.data;
    
    if (isDefined(state.user.impersonate)) {
      await dispatch(userActions.fetch.action({ id: state.user.impersonate }));
    } else if (session.userId) {
      await dispatch(userActions.fetch.action({ id: session.userId }));
    }
    
    return session;
  },
);

export const sessionActions = {
  logIn: {
    local: localLogIn,
    oauth: oauthLogIn,
  },
  logOut,
  register: {
    init: initLocalRegistration,
    local: registerLocal,
  },
  fetch,
  init: actionCreator<void>('INIT'),
};
