import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { UIState } from './ui-types';
import { uiActions } from './ui-actions';
import { userActions } from '../user';

export const initialUIState: UIState = {
  pageTitle: '',
  pageDescription: '',
  previewMode: false,
  showHeader: false,
  showInfoBar: false,
}

export const uiReducer = reducerWithInitialState(initialUIState)
  .case(uiActions.SEO, (state, payload) => {
    return { ...state, ...payload };
  })
  .case(uiActions.showHeader, (state, payload) => {
    return { ...state, showHeader: payload };
  })
  .case(uiActions.showInfoBar, (state, payload) => {
    return { ...state, showInfoBar: payload };
  })
  .case(uiActions.previewMode, (state, payload) => {
    return { ...state, previewMode: payload };
  })
