import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import  thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import { AnyAction } from 'typescript-fsa';

import { StoreState } from './types';
import { sessionActions, sessionReducer, initialSessionState } from './session';
import { uiActions, uiReducer, initialUIState } from './ui';
import { useDispatch } from 'react-redux';
import { initialUserState, userReducer } from './user';
import { isDefined } from '@adair/core-ui';
import { initial } from 'lodash-es';
import produce from 'immer';
import { baseApi } from '../util/api';


// 
// actions
// 

export const actions = {
  session: sessionActions,
  ui: uiActions,
};



// 
// reducers
// 

export const reducers = combineReducers({
  session: sessionReducer,
  user: userReducer,
  ui: uiReducer,
});




// 
// Create Store
// 

// rehydrate state on app start

let initialState: StoreState = {
  session: initialSessionState,
  user: initialUserState,
  ui: initialUIState,
};

initialState = produce(initialState, (draft) => {
  draft.user.impersonate = window.sessionStorage.getItem('impersonate');
  if (isDefined(draft.user.impersonate))
    draft.ui.previewMode = true;
})

if (isDefined(initialState.user.impersonate)) {
  baseApi.axios.defaults.headers.common['X-Impersonate'] = initialState.user.impersonate
}


const thunk: ThunkMiddleware<StoreState, AnyAction> = thunkMiddleware;
const middlewareEnhancer = applyMiddleware(thunk);
const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

export const store = createStore(reducers, initialState, composedEnhancers);

export * from './types';

export const useThunkDispatch = () => useDispatch<typeof store.dispatch>();
