import React from 'react';
import { Container, Columns, Column, AnchorButton, Heading } from '@adair/core-ui';
import { IconAlertTriangleFill } from '@adair/core-ui/lib/icons';

import { ErrorTemplate } from '../../templates/error/errorTemplate';

export const Error404Page: React.FC = () => {
  return (
    <ErrorTemplate>
      <Container>
        <Columns className="t--center">
          <Column>
            <IconAlertTriangleFill className="c--warning" size={24} />
            <p>error 404</p>
            <Heading size={4} margin={[null,null,6]}>This Page does not exists.</Heading>
            <p className="c--gray600">
                <AnchorButton appearance="outline" href="/">Home Page</AnchorButton>
            </p>
          </Column>
        </Columns>
      </Container>
    </ErrorTemplate>
  )
}
