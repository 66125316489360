import React from 'react';
import { Router } from '@reach/router';

import { BaseTemplate } from "../../templates";
import { FavoritesDashPage } from './favorites-dash';
import { FavoritesPlanPage } from './favorite-plans';
import { FavoritesTourPage } from './favorite-tours';
import { FavoritesImagePage } from './favorite-images';
import { Route } from '../../../components';
import { SEO } from '../../partials';
import { Error404Page } from '../error/error-404';

export const FavoritesIndexPage: React.FC = () => {
  return (
    <BaseTemplate
      navTitle="My Favorites"
      navLinks={[
        { label: 'Images', url: '/favorites/images' },
        { label: 'Home Plans', url: '/favorites/plans' },
        { label: 'Virtual Tours', url: '/favorites/tours' },
      ]}
    >
      <SEO title={'My Favorites'} />
      <Router primary={false} basepath="/favorites">
        <Route path="/" component={FavoritesDashPage} />
        <Route path="/plans" component={FavoritesPlanPage} />
        <Route path="/tours" component={FavoritesTourPage} />
        <Route path="/images" component={FavoritesImagePage} />
        <Route default component={Error404Page} />
      </Router>
    </BaseTemplate>
  )
}
