import React from 'react';
import { navigate } from '@reach/router';


import { AnchorButton, IAnchorButtonProps } from '@adair/core-ui';

interface ILinkButtonProps extends IAnchorButtonProps {}

export const LinkButton: React.FC<ILinkButtonProps> = (props) => {
  const { onClick, ...buttonProps } = props;

  function handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()
    if (props.href) {
      navigate(props.href);
    }
  }

  return (
    <AnchorButton {...buttonProps} onClick={handleClick} />
  )
}
