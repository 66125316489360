/** Modified from https://github.com/fkhadra/react-toastify/blob/master/src/utils/eventManager.js */

import { Enum } from '@adair/core-ui';

export const BannerAction = Enum(
  'SHOW',
  'UPDATE',
  'DISMISS',
  'CLEAR',
  'ON_CHANGE',
  'CONTAINER_MOUNT',
  'CONTAINER_UNMOUNT',
);
export type BannerAction = Enum<typeof BannerAction>;

type ActionCallback = (params: Record<string, any>) => void;

export const bannerManager = {
  list: new Map<BannerAction, ActionCallback[]>(),

  on(eventType: BannerAction, callback: ActionCallback) {
    if (!this.list.has(eventType)) {
      this.list.set(eventType, []);
    }
    const event = this.list.get(eventType);
    if (event) {
      event.push(callback);
    }
    return this;
  },

  off(event: BannerAction) {
    this.list.delete(event);
    return this;
  },

  /**
   * Enqueue the event at the end of the call stack
   * Doing so let the user call toast as follow:
   * toast('1')
   * toast('2')
   * toast('3')
   * Without setTimemout the code above will not work
   */
  emit(event: BannerAction, params: Record<string, any>) {
    const events = this.list.get(event);
    if (events) {
      events.forEach(callback =>
        setTimeout(() => {
          callback(params);
        }, 0),
      );
    }
  },
};
