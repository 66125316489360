import React, { Fragment } from 'react';
import cn from 'classnames';
import {
  Tooltip,
  Close,
  Pane,
  Tag,
  toaster,
  safeInvoke,
  IProps,
} from '@adair/core-ui';
import { WebAsset } from '@adair/core-client-utilities/lib/web-asset/types';
import { IconAlertTriangle } from '@adair/core-ui/lib/icons';

import { api } from '../../../util/api';


export interface AssetCardProps extends IProps {
  asset: WebAsset;
  onUnFavoriteStart?: () => void;
  onUnFavoriteSuccess?: () => void;
  onUnFavoriteFailure?: () => void;
  onClick?: () => void;
}

export const AssetCard: React.FC<AssetCardProps> = (props) => {

  function unfavoriteAsset(e?: React.SyntheticEvent) {
    e?.preventDefault();
    e?.stopPropagation();
    safeInvoke(props.onUnFavoriteStart);

    api.favorite.unfavoriteAsset(props.asset.id).request
      .then(() => {
        safeInvoke(props.onUnFavoriteSuccess);
      })
      .catch((err) => {
        toaster.danger({ text: 'There was an Uknown error removing this plan' })
        safeInvoke(props.onUnFavoriteFailure);
      })
  }

  return (
    <div
      className={cn('asset-card', props.className)}
      style={props.style}
      onClick={props.onClick}
    >
      <div
        className="asset-card__inner"
        style={{
          backgroundImage: `url(${props.asset.thumbUrl})`,
          backgroundPosition: `${props.asset.focus[0]}% ${props.asset.focus[1]}%`
        }}
      >
        <div className="asset-card__overlay d--flex flex--column justify--end">
          <div className="unfavorite">
            <Tooltip content="Remove from Favorites" referenceTagName="span" hoverOpenDelay={500}>
              <Close
                appearance="fill"
                onClick={unfavoriteAsset}
              />
            </Tooltip>
          </div>
          <Pane padding={[4]}>
            {props.asset.isArchived &&
              <Tooltip
                content="This image has been flagged as outdated or features items that are no longer available. It may be removed in the future."
                referenceTagName="span"
                hoverOpenDelay={150}
              >
                <Tag
                  appearance="fill"
                  intent="warning"
                  label={<IconAlertTriangle />}
                  // shape="circular"
                  className="elevate--2"
                />
              </Tooltip>
            }
          </Pane>
        </div>
      </div>
    </div>
  )
}