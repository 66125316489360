import React, { useEffect } from 'react';
import useSWR from 'swr';
import {
  Container,
  Columns, Column,
  Buttons,
  Loader,
  Pane,
  Heading,
  Tag,
  isDefined,
  isUSStateId, getState,
} from '@adair/core-ui';
import { HomeSite } from '@adair/core-client-utilities/lib/home-site';
import { IconLocation } from '@adair/core-ui/lib/icons';

import { api } from '../../../util/api';
import { handleUnauthorized } from '../../../util/catch-401';
import { BaseTemplate } from '../../templates';
import { PageLoadError, SEO } from '../../partials';
import { LinkButton } from '../../../components';
import { useUser } from '../../../hooks';

export function getHomeSiteName(homeSite: HomeSite) {
  if (homeSite.siteAddress) {
    return homeSite.siteAddress;
  }

  if (homeSite.siteCity) {
    return `${homeSite.siteCity} Home`;
  }

  if (homeSite.siteCounty) {
    return `${homeSite.siteCounty} County Home`
  }

  if (homeSite.siteState) {
    return `${isUSStateId(homeSite.siteState) ? getState(homeSite.siteState).label : homeSite.siteState} Home`
  }

  return 'New Home';
}

export function getLocationStage(site: HomeSite): 'complete' | 'city' | 'county' | 'state' | 'none' {
  if (site.siteAddress) return 'complete';
  if (site.siteCity) return 'city';
  if (site.siteCounty) return 'county';
  if (site.siteState) return 'state';
  return 'none';
}

export const HomeSiteIndexPage: React.FunctionComponent = (props) => {
  const user = useUser();
  const {data: homeSites, error, isValidating } = useSWR(['homesites', user?.id], async () => {
    return (await (api.homeSite.fetchAll()).request).data;
  })

  useEffect(() => {
    if (isDefined(error)) {
      handleUnauthorized(error)
    }
  }, [error])

  function renderHomeSites() {
    return (
      <Container maxWidth="lg">
        <Columns>
          {homeSites?.map((homeSite) => {
            const locationStage = getLocationStage(homeSite);
            return (
              <Column key={homeSite.id} span={{ xs: 12, md: 6 }}>
                <Pane
                  padding={[10, 6]}
                  bgColor="gray100"
                  border="small"
                  borderColor="gray200-alpha"
                  borderRadius="large"
                  className="t--center h--100"
                  style={{ position: 'relative' }}
                >
                  <div className="c--gray300"><IconLocation size={18} /></div>
                  <Heading size={4} margin={[4, null, 3]}>
                    {getHomeSiteName(homeSite)}
                  </Heading>
                  {(locationStage !== 'none' && locationStage !== 'state') &&
                    <div className="f--2 c--gray500">
                      {locationStage === 'complete'
                        ? `${homeSite.siteCity}, ${homeSite.siteState}`
                        : `${isUSStateId(homeSite.siteState) ? getState(homeSite.siteState).label : homeSite.siteState}`
                      }
                    </div>
                  }
                  <Buttons margin={[8, null, null]} flexJustify="center">
                    <LinkButton
                      appearance="outline"
                      href={`/homes/${homeSite.id}`}
                      label="View"
                      className="stretched-link"
                      intent="primary"
                    />
                  </Buttons>
                </Pane>
              </Column>
            )
          })}
        </Columns>
      </Container>
    )
  }

  function renderMarketing() {
    return (
      <Container maxWidth="lg">
        <Columns>
          <Column>
            <Pane margin={[null, null, 8]} className="t--center">
              <Tag size="large" intent="warning">Coming Soon</Tag>
            </Pane>
            <Heading size={5} className="t--center">
              As you begin working with Adair you'll get access to tools and resources for managing your home build.
            </Heading>
            <Pane margin={[10, null]} className="t--center">
              <img src={`${process.env.REACT_APP_S3_URL}/home-index-graphic.png`} className="img-fluid" />
            </Pane>
            <Columns>
              <Column span={{ xs: 12, sm: 6 }}>
                <Heading size={2} margin={[null, null, 4]}>Track Build Progress</Heading>
                <p>Easily track the progress of your home build. See which milestones have been completed and whats upcoming.</p>
              </Column>
              <Column span={{ xs: 12, sm: 6 }}>
                <Heading size={2} margin={[null, null, 4]}>Download Documents</Heading>
                <p>All of your important contracts, reports, drawings, and more – all in one convenient location.</p>
              </Column>
              <Column span={{ xs: 12, sm: 6 }}>
                <Heading size={2} margin={[null, null, 4]}>Pro Team Directory</Heading>
                <p>Need some help? Easily track down the best person to contact with a directory of Pro Team members assigned to your build.</p>
              </Column>
              <Column span={{ xs: 12, sm: 6 }}>
                <Heading size={2} margin={[null, null, 4]}>...And More</Heading>
                <p>We’re just getting started! Our team is actively working on new features to make Adair the best homebuilding experience.</p>
              </Column>
            </Columns>
            {/* <Message
              className={marginClass([8, null, null])}
              appearance="fill"
              intent="warning"
              text="If you are a current customer and your home is not showing, please contact your Home Ownership Counselor to get it connected to your myAdair account."
            /> */}
          </Column>
        </Columns>
      </Container>
    )
  }

  return (
    <BaseTemplate navTitle="My Homes">
      <SEO title="My Homes" />
      <Loader isLoading={isValidating} />

      {error && error.response?.status !== 401 &&
        <Container maxWidth="lg" margin={[6, null]}>
          <PageLoadError />
        </Container>
      }

      {isDefined(homeSites) &&
        <Pane as="section" padding={[8, null]}>
          {homeSites?.length ? renderHomeSites() : renderMarketing()}
        </Pane>
      }
    </BaseTemplate >
  );
};
