import React, { Fragment, useEffect, useState } from 'react';
import { navigate, useParams } from '@reach/router';
import { find, findIndex } from 'lodash-es';
import { format as formatDate } from 'date-fns';
import cn from 'classnames';
import {
  Container, Columns, Column,
  Box,
  ProgressBar,
  Buttons, Button,
  Tag,
  Heading,
  Pane,
  Hr,
  List,
  paddingClass, marginClass,
  sortObjects, isDefined, TextBlock, AnchorButton,
} from '@adair/core-ui';
import { HomeSite } from '@adair/core-client-utilities/lib/home-site';
import { Document } from '@adair/core-client-utilities/lib/document';
import {
  ScheduleMilestone,
  SchedulePhase,
  TimelineSchedule
} from '@adair/core-client-utilities/lib/timeline';
import { useApi } from '@adair/core-client-utilities/lib/hooks';
import {
  IconArrowLeft,
  IconArrowRight,
  IconCheckCircleFill,
  IconCircle,
} from '@adair/core-ui/lib/icons';

import { api } from '../../../util/api';
import { LinkButton } from '../../../components';
import { Error404Page } from '../error/error-404';
import { DocumentTile, SEO, TimelineBar } from '../../partials';
import { getHomeSiteName } from './home-site-index';

interface HomeTimelinePageProps {
  children?: never;
  homeSite: HomeSite;
  phases: TimelineSchedule['phases'];
}

export const HomeTimelinePage: React.FC<HomeTimelinePageProps> = (props) => {
  const params = useParams();
  const [activePhaseIndex, setActivePhaseIndex] = useState<number>(-1);
  const [activePhase, setActivePhase] = useState<SchedulePhase | undefined>();
  const [currentPhase, setCurrentPhase] = useState<SchedulePhase | undefined>();
  const [prevPhase, setPrevPhase] = useState<SchedulePhase | undefined>();
  const [nextPhase, setNextPhase] = useState<SchedulePhase | undefined>();
  const [nextMilestone, setNextMilestone] = useState<ScheduleMilestone | undefined>();
  const [relatedDocs, setRelatedDocs] = useState<Document[]>([]);
  const { data: resources } = useApi(api.resources.fetchAll);

  const relatedResources = resources && activePhase ? resources.filter(r => {
    return r.timelinePhases?.map(p => p.id).includes(activePhase.id);
  }) : [];

  useEffect(() => {
    const activePhaseIndex = findIndex(props.phases, ['slug', params.phase]);
    setActivePhaseIndex(activePhaseIndex);
    setActivePhase(props.phases[activePhaseIndex]);
    setPrevPhase(props.phases[activePhaseIndex - 1]);
    setNextPhase(props.phases[activePhaseIndex + 1]);
  }, [props.phases, params.phase])

  useEffect(() => {
    const currentPhase = find(props.phases, ['isCurrent', true]);
    const nextMilestone = !!currentPhase ? find(currentPhase.milestones, ['completed', null]) : undefined;
    setCurrentPhase(currentPhase);
    setNextMilestone(nextMilestone);
  }, [props.phases])

  useEffect(() => {
    let relatedDocs: Document[] = [];
    switch (activePhase?.code) {
      case 'phase:v1:200':
        relatedDocs = props.homeSite.documents.filter((d) => {
          if (!d.documentType) return false;
          return [
            'property-evaluation-report',
            'plat-map',
            'jurisdiction-checklist',
            'floor-plan',
            'earnest-money-agreement',
            'financial-preapproval',
          ].includes(d.documentType);
        })
        break;
      case 'phase:v1:300':
        relatedDocs = props.homeSite.documents.filter((d) => {
          if (!d.documentType) return false;
          return [
            'quote-binder',
            'redlines',
            'agreement-documents',
            'payment-agreement',
            'order-binder',
            'basement-addendum',
            'price-protection-letter',
            'customer-packet',
          ].includes(d.documentType);
        })
        break;
      case 'phase:v1:500':
        relatedDocs = props.homeSite.documents.filter((d) => {
          if (!d.documentType) return false;
          return [
            'order-binder',
            'basement-addendum',
            'customer-packet',
          ].includes(d.documentType);
        })
        break;
    }

    setRelatedDocs(sortObjects(relatedDocs, 'lastModifiedDate', ['desc']));
  }, [activePhase, props.homeSite.documents])

  // 
  // Actions
  // 

  // 
  // Render
  // 

  if (!props.homeSite || !activePhase) return <Error404Page />;

  const completedPhaseCount = activePhase.milestones.filter((p) => {
    return !!p.completed;
  }).length

  function renderPageNavigation() {
    if (!activePhase) return null;
    return (
      <Buttons flexJustify="center" appearance="outline" density="compact" isCombined>
        {isDefined(prevPhase)
          ? <LinkButton
            href={`/homes/${props.homeSite.id}/timeline/${prevPhase.slug}`}
            appearance="outline"
            density="compact"
            label={<IconArrowLeft />}
          />
          : <Button isDisabled label={<IconArrowLeft />} />
        }
        {isDefined(nextPhase)
          ? <LinkButton
            href={`/homes/${props.homeSite.id}/timeline/${nextPhase.slug}`}
            appearance="outline"
            density="compact"
            label={<IconArrowRight />}
          />
          : <Button isDisabled label={<IconArrowRight />} />
        }
      </Buttons>
    )
  }

  return (
    <Fragment>
      <SEO title={`${getHomeSiteName(props.homeSite)} | Timeline`} />
      <Pane as="section" padding={[8, null]}>
        <Container maxWidth="lg">
          <TimelineBar
            phases={props.phases ?? []}
            onClick={(phase) => { navigate(`/homes/${props.homeSite.id}/timeline/${phase.slug}`) }}
          />

          <Columns className={marginClass([6, null, null])} justify="center">
            <Column span="shrink">
              <Heading as="span" size={1} margin={[null, 2, null, null]}>Current Phase</Heading>
              <br className="d--none::md" />
              <span>{currentPhase?.label ?? 'unknown'}</span>
            </Column>
            <Column span="shrink">
              <Heading as="span" size={1} margin={[null, 2, null, null]}>Next Milestone</Heading>
              <br className="d--none::md" />
              <span>{nextMilestone?.label ?? 'N/A'}</span>
            </Column>
            <Column span="shrink" className={cn('d--none', 'd--block::sm')}>
              <Heading as="span" size={1} margin={[null, 2, null, null]}>Last Updated</Heading>
              <br className="d--none::md" />
              <span>{formatDate(props.homeSite.updatedAt, 'M/dd/yyyy') ?? 'unknown'}</span>
            </Column>
          </Columns>
        </Container>
      </Pane>

      <Hr />

      <Pane as="section" padding={[8, null]}>
        <Container maxWidth="lg">
          <div className="timeline-page">
            <Columns align="middle" className={cn('timeline-page__header', marginClass([null, null, 4]))}>
              <Column span={12} className={cn(paddingClass([null, null, 2]), 't--center t--left::md')}>
                <Heading size={1} color="gray400" className="t--uc">Phase {activePhaseIndex + 1}</Heading>
              </Column>
              <Column className="t--center t--left::md">
                <Heading size={5}>
                  {activePhase.label}
                </Heading>
              </Column>
              <Column span="shrink" className="d--none d--block::md">
                {renderPageNavigation()}
              </Column>
            </Columns>

            <Pane className="timeline-page__description" margin={[null, null, 8]}>
              <TextBlock>
                <div
                  dangerouslySetInnerHTML={{ __html: activePhase.marketingDescription ?? '' }}
                />
              </TextBlock>
            </Pane>

            <div className="timeline-page__related">
              {relatedDocs.length > 0 &&
                <Fragment>
                  <Heading size={2} margin={[null, null, 4]}>Related Documents</Heading>
                  <Box className={marginClass([null, null, 8])} density="relaxed" >
                    {relatedDocs.map((doc, i) => {
                      return (
                        <DocumentTile
                          key={doc.id} padding={[4]}
                          border={i > 0 ? { width: 'small', location: 'top' } : undefined}
                          borderColor="gray200"
                          borderRadius="none"
                          document={doc}
                        />
                      )
                    })}
                  </Box>
                </Fragment>
              }
              {relatedResources.length > 0 &&
                <Fragment>
                  <Heading size={2} margin={[null, null, 4]}>Related Resources</Heading>
                  <Box className={marginClass([null, null, 8])} density="relaxed">
                    {relatedResources.map((resource, i) => {
                      return (
                        <Pane
                          key={resource.id}
                          padding={[4]}
                          border={i > 0 ? { width: 'small', location: 'top' } : undefined}
                          borderColor="gray200"
                        >
                          <Columns align="middle">
                            <Column>
                              <Heading size={'sm'} color="gray500" className="t--uc" >{resource.resourceType}</Heading>
                              <Heading size={2} >{resource.title}</Heading>

                            </Column>
                            <Column span="shrink">
                              <AnchorButton
                                href={resource.url}
                                density="compact"
                                appearance="outline"
                                target="_blank"
                                label={'View'}
                              />
                            </Column>
                          </Columns>
                        </Pane>
                      )
                    })}
                  </Box>
                </Fragment>
              }
            </div>
            <div className="timeline-page__sidebar">
              <Pane bgColor="gray100" margin={[null, null, 8]} borderRadius={{ size: 'medium', location: 'bottom' }}>
                <ProgressBar
                  size="small"
                  shape="squared"
                  intent="success"
                  value={completedPhaseCount / activePhase.milestones.length * 100}
                />
                <Pane padding={[4, 6]} border={{ width: 'small', location: 'bottom' }} borderColor="gray200">
                  <Columns align="middle">
                    <Column>
                      <Heading size={2}>Milestones</Heading>
                    </Column>
                    <Column span="shrink">
                      {completedPhaseCount === activePhase.milestones.length
                        ? <Tag shape="circular" intent="success">Complete</Tag>
                        : <Tag shape="circular">{completedPhaseCount} / {activePhase.milestones.length}</Tag>
                      }
                    </Column>
                  </Columns>
                </Pane>
                <Pane padding={[6]}>
                  <List>
                    {activePhase.milestones.map((milestone) => {
                      return (
                        <li key={milestone.id} className={cn('d--flex', paddingClass([null, null, 3]))}>
                          <Pane as="span" margin={[null, 3, null, null]}>
                            {!!milestone.completed
                              ? <IconCheckCircleFill className="c--success" />
                              : <IconCircle className="c--gray300" />
                            }
                          </Pane>
                          <div>
                            <div>{milestone.label}</div>
                            {!milestone.completed && milestone.scheduled && milestone.showScheduledDate &&
                              <div className="f--sm c--gray700">
                                Scheduled for <strong>{formatDate(milestone.scheduled, 'MM/dd/yyyy')}</strong>
                              </div>
                            }
                          </div>
                        </li>
                      )
                    })}
                  </List>
                </Pane>
              </Pane>
            </div>
          </div>
        </Container>
      </Pane >
      <Pane as="section" className={cn('d--none::md')} padding={[4, null, 8]}>
        <Container>
          {renderPageNavigation()}
        </Container>
      </Pane>
    </Fragment >
  );
};
