import React from 'react';
import { Link, navigate } from '@reach/router';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion'
import {
  Container, Columns, Column,
  Menu,
  Pane,
  Popover,
  Tag,
  marginClass,
  MenuItem,
} from '@adair/core-ui';
import { IconMenu, IconAccount, IconClose } from '@adair/core-ui/lib/icons';

import { useUser, useAuth } from '../../../hooks';
import { StoreState, useThunkDispatch } from '../../../store';
import { sessionActions } from '../../../store/session';
import { MenuLink } from '../../../components';
import { includes } from 'lodash-es';
import { uiActions } from '../../../store/ui';
import { useSelector } from 'react-redux';


export const Header: React.FC = () => {
  const dispatch = useThunkDispatch();
  const { isAuthenticated } = useAuth();
  const user = useUser();
  const [isMobileOpen, setIsMobileOpen] = React.useState(false);
  const isPreviewMode = useSelector((state: StoreState) => {
    return !!state.user.impersonate;
  })

  function handleLogOut() {
    dispatch(sessionActions.logOut.action())
      .then(() => {
        navigate('/login');
      })
  }

  function handleMenuClick() {
    setIsMobileOpen(!isMobileOpen);
  }

  function handleMobileClose() {
    setIsMobileOpen(false);
  }

  function impersonateUser() {
    dispatch(uiActions.previewMode(true));
  }

  //
  // Render
  //

  function renderAccountDropdown() {
    return (
      <Menu appearance="pill" isVertical>
        <Pane padding={[2, 4, 3]}>
          <span className="f--sm c--gray500">Logged in as</span><br />
          <strong>{user?.customerProfile ? user.customerProfile.fullName : 'Unknown'}</strong>
        </Pane>
        <Menu.Divider />
        <MenuLink
          href="/account"
          label="My Account"
        />
        { includes(user?.roles, 'admin') &&
          <MenuItem
            label="Preview as Customer"
            onClick={impersonateUser}
          />
        }
        <Menu.Item label="Sign Out" isDisabled={isPreviewMode} onClick={handleLogOut} />
      </Menu>
    )
  }

  function renderMobileNav() {
    const variants = {
      hidden: { translateX: '-100%' },
      visible: { translateX: '0%' }
    }
    const linkVariants = {
      hidden: {
        opacity: 0,
        x: -16,
        transition: { duration: .2 }
      },
      visible: {
        opacity: 1,
        x: 0,
        transition: { duration: .35, ease: 'easeInOut' }
      }
    }

    return (
      <AnimatePresence>
        {isMobileOpen &&
          <motion.div
            key="mobile-nav"
            id="mobile-nav"
            className={'show show-active'}
            initial={'hidden'}
            exit={'hidden'}
            animate={'visible'}
            variants={variants}
            transition={{
              type: 'spring',
              damping: 10,
              stiffness: 100,
              mass: .1,
              staggerChildren: .075,
              delayChildren: .1,
            }}
          >
            <Pane padding={[8]}>
              <ul className={'list'}>
                <motion.li variants={linkVariants} className={'mn__link'}>
                  <Link to="/" onClick={handleMobileClose}>Dashboard</Link>
                </motion.li>
                <motion.li variants={linkVariants} className={'mn__link'}>
                  <Link to="/favorites" onClick={handleMobileClose}>My Favorites</Link>
                </motion.li>
                <motion.li variants={linkVariants} className={'mn__link'}>
                  <Link to="/home-quotes" onClick={handleMobileClose}>Home Quotes</Link>
                </motion.li>
                <motion.li variants={linkVariants} className={'mn__link'}>
                  <Link to="/homes" onClick={handleMobileClose}>My Homes</Link>
                </motion.li>
                <motion.li variants={linkVariants} className={'mn__link'}>
                  <Link to="/resources" onClick={handleMobileClose}>Resources</Link>
                </motion.li>
              </ul>
            </Pane>
          </motion.div>
        }
        {isMobileOpen &&
          <motion.div
            key="mn-backdrop"
            id="mn-backdrop"
            className={cn({ show: isMobileOpen, 'show-active': isMobileOpen })}
            onClick={handleMobileClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        }
      </AnimatePresence>
    )
  }

  return (
    <div className="global-nav">
      <div className="gn__main">
        <Container>
          <Columns align="middle">
            <Column className="d--none::lg" span="shrink">
              <Menu>
                <Menu.Link href="#" onClick={handleMenuClick}>
                  {isMobileOpen ? <IconClose /> : <IconMenu />}
                </Menu.Link>
              </Menu>
            </Column>
            <Column span={{ lg: "shrink" }} className="d--flex align-items--center justify--center">
              <Link to="/">
                <img src="/my-adair-logo-inverse.svg" height="22px" alt="myAdair" />
              </Link>
              <Tag
                shape="circular"
                size="small"
                appearance="fill"
                intent="success"
                className={marginClass([null, null, null, 2])}
                label="Beta"
              />
            </Column>
            <Column className="d--none d--block::lg">
              <Menu size="small">
                <MenuLink href="/">Dashboard</MenuLink>
                <MenuLink href="/favorites">My Favorites</MenuLink>
                <MenuLink href="/home-quotes">Home Quotes</MenuLink>
                <MenuLink href="/homes">My Homes</MenuLink>
                <MenuLink href="/resources">Resources</MenuLink>
              </Menu>
            </Column>
            <Column span="shrink">
              <Menu>
                {isAuthenticated
                  ? <Menu.Link href="#">
                      <Popover content={renderAccountDropdown()} trigger="click"><IconAccount /></Popover>
                    </Menu.Link>
                  : <Menu.Link href="/login" label="login" />
                }
              </Menu>
            </Column>
          </Columns>
        </Container>
      </div>
      { renderMobileNav()}
    </div>
  )
}
