import React, { useEffect, useState } from 'react';
import { debounce, findIndex } from 'lodash-es';
import cn from 'classnames';
import {
  BreakpointValues,
  Pane,
  Popover,
  safeInvoke,
} from '@adair/core-ui';
import {
  SchedulePhase,
  TimelineSchedule,
} from '@adair/core-client-utilities/lib/timeline';

export interface TimelineBarProps {
  children?: never;
  phases: TimelineSchedule['phases'];
  onClick?: (phase: SchedulePhase) => void;
}

export const TimelineBar: React.FC<TimelineBarProps> = (props) => {
  const [disablePopover, setDisablePopover] = useState(true);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })
  
  useEffect(() => {
    handleWindowResize();
  }, [])

  const currentPhaseIndex = findIndex(props.phases, ['isCurrent', true]);

  const handleWindowResize = debounce(() => {
    if (window.outerWidth > BreakpointValues.lg) {
      setDisablePopover(true);
    } else {
      setDisablePopover(false);
    }
  }, 100);

  return (
    <div className="timeline">
      { props.phases.map(((phase, i) => {
        let milestonesPercentComplete = 0;

        if (currentPhaseIndex < 0) { }
        else if (currentPhaseIndex > i) {
          milestonesPercentComplete = 100;
        }
        else if (currentPhaseIndex === i) {
          const completeMilestones = phase.milestones.filter(m => !!m.completed).length;
          milestonesPercentComplete = completeMilestones / phase.milestones.length * 100;
        }
        return (
          <TimelineBarPhase
            key={phase.id}
            title={phase.shortLabel}
            isActive={phase.isCurrent}
            onClick={() => { safeInvoke(props.onClick, phase) }}
            percentComplete={milestonesPercentComplete}
            disablePopover={disablePopover}
          />
        )
      }))}
    </div>
  )
}

interface TimelineBarPhaseProps {
  disablePopover?: boolean;
  isActive?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  percentComplete?: number;
  title: string | null;
}

const TimelineBarPhase: React.FC<TimelineBarPhaseProps> = (props) => {

  function handleClick(e: React.MouseEvent) {
    safeInvoke(props.onClick, e);
  }

  return (
    <Popover
      trigger="hoverTarget"
      isDisabled={props.disablePopover}
      hoverOpenDelay={400}
      content={<Pane padding={[4,6]}>{props.title}</Pane>}
    >
      <div
        className={cn(
          'timeline__phase',
          {
            '-complete': props.percentComplete! >= 100,
            '-active': props.isActive,
          }
        )}
        onClick={handleClick}
        title={props.title ?? ''}
      >
        <div className="timeline__label">{props.title}</div>
        {(props.isActive || props.percentComplete! >= 100) &&
          <div className="timeline__bar" style={{ width: props.percentComplete + '%' }} />
        }
        <svg className="timeline__phase-arrow" viewBox="0 0 20 48">
          <polygon className="btm" points="7 0 3 0 3 48 7 48 19 24 7 0" />
          <polygon className="top" points="4 0 0 0 0 48 4 48 16 24 4 0" />
        </svg>
      </div>
    </Popover>
  )
}

TimelineBarPhase.defaultProps = {
  percentComplete: 0,
  isActive: false,
}
