import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import cn from 'classnames';
import { isUndefined, reject } from 'lodash-es';
import useSWR from 'swr';
import {
  Container, Columns, Column,
  Pane,
  Heading, Text,
  marginClass,
  isDefined,
  Loader,
} from '@adair/core-ui';
import { WebAsset } from '@adair/core-client-utilities/lib/web-asset/types';
import { Favorite, isAssetFavorite } from '@adair/core-client-utilities/lib/favorite';

import { api } from '../../../util/api';
import { useUser } from '../../../hooks';
import { handleUnauthorized } from '../../../util/catch-401';
import {
  ImageGalleryOverlay,
  ImageGalleryApi,
  FavoriteCard,
  PageLoadError
} from '../../partials';


export const FavoritesImagePage = () => {
  const user = useUser();
  const galleryApi = useRef<ImageGalleryApi>();
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const { data, isValidating, error, mutate } = useSWR(['favorites', user?.id], async () => {
    return (await api.favorite.fetchAll().request).data;
  });
  const assetFavorites = useMemo<Favorite<WebAsset>[]>(() => {
    return isUndefined(data)
      ? []
      : data.filter((favorite) => isAssetFavorite(favorite))
  }, [data])
  const assets = useMemo<WebAsset[]>(() => {
    return assetFavorites.map((favorite) => favorite.model).filter(isDefined)
        
  }, [assetFavorites])

  useEffect(() => {
    if (isDefined(error)) {
      handleUnauthorized(error)
    }
  }, [error])

  function handleUnfavoriteStart(assetId: string) {
    const updatedFavorites = reject(assetFavorites, ['modelId', assetId]);
    mutate(updatedFavorites, false);
  }

  function handleUnfavoriteFailure() {
    // forces refetch of favorites to pull back removed favorite
    mutate();
  }

  function openGallery(id?: string) {
    setIsGalleryOpen(true);
    if (id) {
      galleryApi.current?.goToId(id);
    }
  }

  function closeGallery() {
    setIsGalleryOpen(false)
  }

  function renderFavorites() {

    if (assetFavorites.length === 0)
      return (
        <Container maxWidth="md" margin={[8, null]}>
          <Pane bgColor="gray100" borderRadius="small" padding={[8, 6]} className="t--center">
            <Heading size={2} margin={[null, null, 2]}>You don't have any saved images</Heading>
            <Text color="gray600">
              Browser our <a href={`${process.env.REACT_APP_WEB_URL}/homes/gallery`}>galleries</a> to get inspired.
            </Text>
          </Pane>
        </Container>
      )

    return (
      <Container maxWidth="lg" margin={[8, null]}>
        <Columns align="middle">
          {assetFavorites.map((fave) => {
            return (
              <Column span={{ xs: 12, md: 6 }} key={fave.id}>
                <FavoriteCard
                  favorite={fave}
                  onUnFavoriteStart={() => handleUnfavoriteStart(fave.model!.id)}
                  onUnFavoriteFailure={handleUnfavoriteFailure}
                  className={cn('max--sm', marginClass([null, 'auto']))}
                  onClick={() => { openGallery(fave.model?.id) }}
                />
              </Column>
            )
          })}
        </Columns>
      </Container>
    )
  }

  return (
    <Fragment>
      <Loader isLoading={isValidating} />
      {error && error.response?.status !== 401
        ? (
          <Container padding={[8, null]} maxWidth="lg">
            <PageLoadError />
          </Container>
        )
        : renderFavorites()
      }
      <ImageGalleryOverlay
        isOpen={isGalleryOpen}
        assets={assets}
        onClose={closeGallery}
        api={galleryApi}
      />
    </Fragment>
  )
}
