import React, { Fragment, useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { Link, navigate, useLocation } from '@reach/router';
import cn from 'classnames';
import { FormApi } from 'informed';
import {
  Container,
  Message, IMessageProps,
  Form,
  Button,
  Columns, Column,
  Pane,
  Heading, Text,
  validate, validators as v,
  marginClass,
} from '@adair/core-ui';
import { IconArrowLeft } from '@adair/core-ui/lib/icons';

import { SEO } from '../../partials';
import { SplitTemplate } from '../../templates';
import { api } from '../../../util/api';

interface PasswordResetState {
  password: string;
  token: string;
}


export const PasswordResetPage: React.FC = (props) => {
  const location = useLocation();
  const [token, setToken] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string | null>(null);
  const [formApi, setFormApi] = React.useState<FormApi<PasswordResetState>>();
  const [successMessage, setSuccessMessage] = useState<IMessageProps>();
  const [errorMessage, setErrorMessage] = useState<IMessageProps>();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setToken(params.get('token'));
    setEmail(params.get('email'));

    if (formApi) {
      formApi.setValue('token', params.get('token'));
    }
  }, [location.search, formApi]);

  async function handleEmailSubmit(value: { email: string }) {
    await api.session.initPasswordReset({ username: value.email }).request
      .then(() => {
        setSuccessMessage({
          heading: 'An email has been sent with instructions',
          text: `If you don't see it within 10 minutes, please check your spam folder.`,
        })
        setErrorMessage(undefined);
      })
      .catch((e) => { })
  }

  function handleFormSubmit(values: PasswordResetState) {
    api.session.resetPassword({ ...values }).request
      .then((resp) => {
        setSuccessMessage({
          text: 'Your password was successfully reset.',
          actionText: 'Login',
          onAction: () => { navigate('/login') }
        })
        setErrorMessage(undefined);
      })
      .catch((error: AxiosError) => {
        const errorCode = error.response ? error.response.status : 500;
        if (errorCode === 422) {
          setErrorMessage({
            text: error.response?.data.message,
          });
        } else if (errorCode === 400) {
          setErrorMessage({
            text: 'The link has expired or is invalid. Please Request another',
            actionText: 'Resend',
            onAction: email ? () => { handleEmailSubmit({ email }) } : undefined,
          });
        } else {
          setErrorMessage({
            text: `There was an unknown error resetting your password. Please try again in a
                   few minutes or contact us if the issue persists.`,
          });
        }
      });
  };

  function renderInitForm() {
    return (
      <Fragment>
        <Heading size={4}>Password Reset</Heading>
        <Text as="p" color="gray500" margin={[1, null, 6]}>
          Please enter the email address associated with your account.
        </Text>
        <Form<{ email: string }> onSubmit={handleEmailSubmit}>
          {({ formState }) => (
            <Form.Field label="Email" errorText={formState.errors.email}>
              <Form.Controls isCombined>
                <Form.Control>
                  <Form.Input
                    field="email"
                    density="relaxed"
                    validate={validate([v.isRequired(), v.isEmail()])}
                  />
                </Form.Control>
                <Form.Control span="shrink">
                  <Button
                    type="submit"
                    density="relaxed"
                    label="Submit"
                    intent="primary"
                  />
                </Form.Control>
              </Form.Controls>
            </Form.Field>
          )}
        </Form>
      </Fragment>
    )
  }

  function renderRegisterForm() {
    return (
      <div>
        <Heading>Password Reset</Heading>
        <Text color="gray500" margin={[1, null, 6]}>
          Set your new password.
        </Text>
        
        { !!errorMessage &&
          <Message
            intent="danger"
            appearance="outline"
            className={cn(marginClass([null, null, 4]))}
            {...errorMessage}
          />
        }
        <Form getApi={setFormApi} onSubmit={handleFormSubmit}>
          {({ formState }) => (
            <Fragment>
              <Form.Field errorText={formState.errors.password}>
                <Form.Control>
                  <Form.Password
                    field="password"
                    placeholder="password"
                    validate={validate([v.isRequired()])}
                    density="relaxed"
                  />
                </Form.Control>
              </Form.Field>
              <Columns className={marginClass([6, null, null])}>
                <Column span={8}>
                  <Button type="submit" label="Submit" intent="primary" isFullWidth />
                </Column>
              </Columns>
              <Form.Hidden field="token" />
            </Fragment>
          )}
        </Form>
      </div>
    )
  }

  return (
    <SplitTemplate>
      <SEO title="Login" />
      <div className="main-content__interior">
        {!!successMessage
          ? (
            <Container maxWidth="md">
              <Message
                intent="success"
                appearance="outline"
                className={cn(marginClass([null, null, 4]))}
                {...successMessage}
              />
              <Pane margin={[4, null, 4]}>
                <Link
                  className={'c--gray500'}
                  to={`/login`}
                >
                  <IconArrowLeft className={marginClass([null, 2, null, null])} />
                  Return to Sign In
                </Link>
              </Pane>
            </Container>)
          : (
            <Container maxWidth="xs">
              { token
                ? renderRegisterForm()
                : renderInitForm()
              }
              <Pane margin={[4, null, 4]}>
                <Link
                  className={'c--gray500'}
                  to={`/login`}
                >
                  <IconArrowLeft className={marginClass([null, 2, null, null])}/>
                  Return to Sign In
                </Link>
              </Pane>
            </Container>
          )
        }
      </div>
    </SplitTemplate>
  )
}
