import React from 'react';
import cn from 'classnames';
import { Button, Buttons, Heading, IProps, marginClass, Message, Pane, safeInvoke, Text, toaster } from "@adair/core-ui";
import {
  Favorite,
  FavoriteModelType
} from '@adair/core-client-utilities/lib/favorite';

import { PlanCard } from '../plan-card/plan-card';
import { TourCard } from '../tour-card/tour-card';
import { AssetCard } from './asset-card';
import { api } from '../../../util/api';

export interface FavoriteCardProps extends IProps {
  favorite: Favorite;
  onUnFavoriteStart?: () => void;
  onUnFavoriteSuccess?: () => void;
  onUnFavoriteFailure?: () => void;
  onClick?: () => void;
}

export const FavoriteCard: React.FC<FavoriteCardProps> = (props) => {

  async function handleRemove() {
    safeInvoke(props.onUnFavoriteStart);

    await api.favorite.delete(props.favorite.id).request
      .then(() => {
        safeInvoke(props.onUnFavoriteSuccess)
      })
      .catch(() => {
        toaster.danger({ text: 'There was an uknown error removing this favorite' })
        safeInvoke(props.onUnFavoriteFailure)
      })
  }

  if (!props.favorite.model) return (
    <Pane
      bgColor="gray800"
      className={props.className}
      style={props.style}
      borderRadius="medium"
    >
      <Heading
        size="sm"
        color="yellow"
        className="t--uc"
        padding={[6, 4, 2, 8]}
      >
        {props.favorite.modelType === FavoriteModelType.TOUR ? 'Virtual Tour' : ''}
        {props.favorite.modelType === FavoriteModelType.PLAN ? 'Home Plan' : ''}
        {props.favorite.modelType === FavoriteModelType.ASSET ? 'Image' : ''}
      </Heading>

      <Pane padding={[4]}>
        <Message
          appearance="fill"
          intent="danger"
          heading="Unable to Find"
        >
          <Text margin={[null, null, 2]}>
            It may be that this item has been removed from our site.
          </Text>
          <Buttons>
            <Button
              size="small"
              label="Remove"
              intent="danger"
              onClick={handleRemove}
            />
          </Buttons>
        </Message>
      </Pane>
    </Pane>
  )

  if (props.favorite.modelType === FavoriteModelType.PLAN) {
    return (
      <PlanCard
        plan={props.favorite.model}
        onUnFavoriteSuccess={props.onUnFavoriteSuccess}
        onUnFavoriteFailure={props.onUnFavoriteFailure}
        onUnFavoriteStart={props.onUnFavoriteStart}
        className={props.className}
        style={props.style}
      />
    )
  }

  if (props.favorite.modelType === FavoriteModelType.TOUR) {
    return (
      <TourCard
        tour={props.favorite.model}
        onUnFavoriteSuccess={props.onUnFavoriteSuccess}
        onUnFavoriteFailure={props.onUnFavoriteFailure}
        onUnFavoriteStart={props.onUnFavoriteStart}
        className={props.className}
        style={props.style}
      />
    )
  }
  
  if (props.favorite.modelType === FavoriteModelType.ASSET) {
    return (
      <AssetCard
        asset={props.favorite.model}
        onUnFavoriteSuccess={props.onUnFavoriteSuccess}
        onUnFavoriteFailure={props.onUnFavoriteFailure}
        onUnFavoriteStart={props.onUnFavoriteStart}
        className={props.className}
        style={props.style}
        onClick={props.onClick}
      />
    )
  }

  return null;
}
