import { createReporter, setupErrorReporting } from "@adair/core-client-utilities/lib/reporter";

// setup reporting credentials for core-client-utilities library
setupErrorReporting(
  parseInt(process.env.REACT_APP_AIRBRAKE_PROJECT_ID ?? '0'),
  process.env.REACT_APP_AIRBRAKE_PROJECT_KEY ?? '',
  process.env.NODE_ENV
)


export const reporter = createReporter(
  parseInt(process.env.REACT_APP_AIRBRAKE_PROJECT_ID ?? '0'),
  process.env.REACT_APP_AIRBRAKE_PROJECT_KEY ?? '',
  process.env.NODE_ENV,
);
