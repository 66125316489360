import React, { useEffect } from 'react';
import { differenceInMinutes } from 'date-fns';
import { useParams, navigate } from '@reach/router';
import { Intent } from '@adair/core-ui';

import { useThunkDispatch } from '../../store';
import { useUser } from '../../hooks';
import { userActions } from '../../store/user';
import { banner } from '../../components/banner';

export const VerifyPage: React.FC = () => {
  const user = useUser();
  const dispatch = useThunkDispatch();
  const { token } = useParams();

  useEffect(() => {
    const timeSinceLogin = user ? differenceInMinutes(Date.now(), user.lastLoginAt!) : -1;
    if (user && user.lastLoginAt &&  timeSinceLogin < 1) {
      // send verify request
      dispatch(userActions.verifyHomeowner({ token }))
        .then(() => {
          banner.success({
            heading: 'Your Account Has Been Successfully Connected',
            text: 'You should now have access to Home quotes, build timelines, and more as they are added to your account',
          })
        })
        .catch((error) => {
          let heading = 'Unable to Connect Your Account';
          let text = 'An unknown error occurred. Please contact us if the issue persists';
          let intent: Intent = 'danger';

          if (error.response.status === 404) {
            heading = 'Link has Expired';
            text = 'We were not able to connect your account. Please contact your Home Ownership Counselor for another link';
          } else if (error.response.status === 400) {
            intent = 'warning';
            heading = 'Your Account has Already Been Connected';
            text = 'You should be good to go. If you are having issues, please contact us for help';
          }

          banner.show({intent, heading, text })
        })
        .then(()=> {
          navigate('/');
        })
    } else {
      navigate(`/login?next_page=/verify/${token}`);
    }
  }, [user]);

  return null;
}
