import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { find } from 'lodash-es';
import useSWR from 'swr';
import { Column, Columns, Message, Field } from '@adair/core-ui';
import { CustomerProfile } from '@adair/core-client-utilities/lib/customer-profile';

import { StoreState, useThunkDispatch } from '../../store';
import { uiActions } from '../../store/ui';
import { userActions } from '../../store/user';
import { SearchInput } from '../../components';
import { api } from '../../util/api';

export const AdminHeader = () => {
  const dispatch = useThunkDispatch();
  const impersonateId = useSelector((state: StoreState) => {
    return state.user.impersonate;
  });
  const previewMode = useSelector((state: StoreState) => {
    return state.ui.previewMode;
  });
  const { data: homeowners, mutate } = useSWR(['customerProfiles'], async () => {
    return (await api.customerProfile.fetchAll().request).data;
  });
  const activeHomeowner = homeowners && impersonateId
    ? find(homeowners, ['userId', impersonateId])
    : null

  useEffect(() => {
    // make sure we get profiles when requesting as employee, not the previewed account
    if (!impersonateId) {
      mutate();
    }
  }, [impersonateId])

  function handleSearchChange(option: CustomerProfile | null) {
    dispatch(userActions.impersonate(option ? option.userId : null));
  }

  function endImpersonationMode() {
    dispatch(uiActions.previewMode(false));
    dispatch(userActions.impersonate.action(null));
  }

  if (!previewMode) return null;

  return (
    <Message
      intent={impersonateId ? 'danger' : 'info'}
      appearance="fill"
      onAction={endImpersonationMode}
      actionText={impersonateId ? 'End' : 'Close'}
      isBanner
      size="small"
      density="compact"
    >
      <Columns align="middle">
        <Column span={{ xs: 12, sm: 'shrink' }}>
          <Field.Label>
            {impersonateId ? 'Previewing As' : 'Preview As'}
          </Field.Label>
        </Column>
        <Column>
          <SearchInput<CustomerProfile>
            field="user"
            idProp="id"
            options={homeowners ?? []}
            onUpdate={handleSearchChange}
            renderLabel="fullName"
            value={activeHomeowner ?? null}
            placeholder="Homeowner Name"
          />
        </Column>
      </Columns>
    </Message>
  )
}