import React, { useEffect, useRef, useState } from 'react';
import { format as formatDate } from 'date-fns';
import { AxiosResponse, Canceler } from 'axios';
import { Link } from '@reach/router';
import { find } from 'lodash-es';
import useSWR from 'swr';
import cn from 'classnames';

import { BaseTemplate } from '../templates';
import { SEO, FavoriteCard, ImageGalleryOverlay, ImageGalleryApi } from '../partials';
import { catchUnauthorized, handleUnauthorized } from '../../util/catch-401';
import { useUser } from '../../hooks';
import {
  Container, Columns, Column,
  Box,
  StaticMap, MapRatioType,
  Heading,
  Pane,
  Hr,
  marginClass,
  sortObjects,
  isDefined,
  isUSStateId, getState,
} from '@adair/core-ui';
import { TimelineSchedule } from '@adair/core-client-utilities/lib/timeline';
import { IconArrowRight } from '@adair/core-ui/lib/icons';

import { api } from '../../util/api';
import { LinkButton } from '../../components';
import { getHomeSiteName, getLocationStage } from './homes/home-site-index';
import { isAssetFavorite } from '@adair/core-client-utilities/lib/favorite';

export const HomePage: React.FC = (props) => {

  const user = useUser();
  const galleryApi = useRef<ImageGalleryApi>();
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const {
    data: homeSites,
    error: hsError
  } = useSWR(['homesites', user?.id], async () => {
    return (await api.homeSite.fetchAll().request).data;
  });
  const {
    data: favorites,
    mutate: refetchFavorites,
    error: favoriteError
  } = useSWR(['favorites', user?.id], async () => {
    return (await api.favorite.fetchAll().request).data;
  });
  const [timelines, setTimelines] = useState<TimelineSchedule[]>([]);
  const galleryImages = favorites?.filter(isAssetFavorite).map(f => f.model).filter(isDefined) ?? [];


  //
  // Lifecycle
  //

  useEffect(() => {
    let req: { request: Promise<AxiosResponse<TimelineSchedule>>, cancel: Canceler }[];
    if (homeSites) {
      req = homeSites.map((hs) => api.homeSite.fetchHomeSiteSchedule({ id: hs.id }));
      Promise.all(req.map((r) => r.request))
        .then((resp) => {
          setTimelines(resp.map(r => r.data));
        })
        .catch(catchUnauthorized)
    }
    return (() => {
      if (!!req) {
        req.forEach((r) => r.cancel);
      }
    })
  }, [homeSites]);

  React.useEffect(() => {
    if (isDefined(hsError)) {
      handleUnauthorized(hsError)
    }

    if (isDefined(favoriteError)) {
      handleUnauthorized(favoriteError)
    }
  }, [hsError, favoriteError])

  //
  // Actions
  //

  function openGallery(id?: string) {
    setIsGalleryOpen(true);
    if (id) {
      galleryApi.current?.goToId(id);
    }
  }

  function closeGallery() {
    setIsGalleryOpen(false)
  }

  //
  // Handlers
  //

  function handleFavoriteRemoved() {
    refetchFavorites();
  }

  //
  // Render
  //

  return (
    <BaseTemplate
      navTitle={`Welcome, ${user?.customerProfile?.firstName}`}
    >
      <SEO title={user?.customerProfile ? `Welcome, ${user.customerProfile.firstName}` : 'Home'} />
      {!!homeSites && homeSites.length > 0 &&
        <Container maxWidth="lg" padding={[8, null]}>
          {/* @todo: determine if a home site is considered 'active' */}
          {homeSites.map((homeSite) => {
            const timeline = find(timelines, ['homeSiteId', homeSite.id]);
            const currentPhase = timeline ? find(timeline.phases, ['isCurrent', true]) : null;
            const nextMilestone = currentPhase ? find(currentPhase.milestones, ['completed', null]) : null;
            const locationStage = getLocationStage(homeSite);
            let locationUrl = [
              homeSite.siteCity,
              homeSite.siteCounty,
              homeSite.siteAddress,
              homeSite.siteState,
              homeSite.siteZipcode,
              'usa',
            ].filter(isDefined).join(',')

            return (
              <Pane key={homeSite.id} bgColor="gray100" borderRadius="large" margin={[null, null, 8]}>
                <Pane padding={[6]}>
                  <Columns>
                    <Column>
                      <Columns align="middle" justify="center">
                        {locationStage !== 'none' &&
                          <Column span="shrink" className={cn('d--none', 'd--block::sm')}>
                            <StaticMap
                              apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY!}
                              center={locationUrl}
                              ratio={MapRatioType.SQUARE}
                              width={120}
                              mapType="terrain"
                              style={{ width: 120 }}
                              className="rounded--full"
                            >
                              {homeSite.siteCity &&
                                <StaticMap.Marker
                                  size="small"
                                  location={locationUrl}
                                />
                              }
                            </StaticMap>
                          </Column>
                        }
                        <Column key={homeSite.id} >
                          <Heading size={5} margin={[null, null, 2]}>
                            <Link to={`/homes/${homeSite.id}`}>
                              {getHomeSiteName(homeSite)}
                            </Link>
                          </Heading>
                          {(locationStage !== 'none' && locationStage !== 'state') &&
                            <div className="f--3 c--gray500">
                              {locationStage === 'complete'
                                ? `${homeSite.siteCity}, ${homeSite.siteState}`
                                : `${isUSStateId(homeSite.siteState) ? getState(homeSite.siteState).label : homeSite.siteState}`
                              }
                            </div>
                          }
                        </Column>
                      </Columns>
                    </Column>
                    <Column span="shrink" className={cn('d--none', 'd--block::md')}>
                      <LinkButton
                        appearance="minimal"
                        href={`/homes/${homeSite.id}`}
                        after={<IconArrowRight />}
                      >View</LinkButton>
                    </Column>
                  </Columns>
                </Pane>
                <Hr />
                <Pane padding={[6]}>
                  <Columns justify="start">
                    <Column span="shrink">
                      <Heading as="span" size={1} margin={[null, 2, null, null]}>Current Phase</Heading>
                      <br className="d--none::lg" />
                      <span>{currentPhase?.label ?? 'N/A'}</span>
                    </Column>
                    <Column span="shrink">
                      <Heading as="span" size={1} margin={[null, 2, null, null]}>Next Milestone</Heading>
                      <br className="d--none::lg" />
                      <span>{nextMilestone?.label ?? 'N/A'}</span>
                    </Column>
                    <Column span="shrink" className={cn('d--none', 'd--block::sm')}>
                      <Heading as="span" size={1} margin={[null, 2, null, null]}>Last Updated</Heading>
                      <br className="d--none::lg" />
                      <span>{formatDate(homeSite.updatedAt, 'M/dd/yyyy') ?? 'unknown'}</span>
                    </Column>
                  </Columns>
                </Pane>

                {/* @todo: pull in timeline bar */}
                {/* <ProgressBar value={30} intent="success" /> */}
              </Pane>
            )
          })}
        </Container>
      }
      <Container maxWidth="lg" padding={[8, null]}>
        <Columns align="middle">
          <Column>
            <Heading size={2}>Recent Favorites</Heading>
          </Column>
          <Column span="shrink">
            <LinkButton
              href="/favorites"
              label="View All"
              appearance="minimal"
              density="compact"
              after={<IconArrowRight />}
            />
          </Column>
        </Columns>
        <Box density="relaxed" appearance="fill">
          {!!favorites?.length
            ? (
              <Box>
                <Pane padding={[6]}>
                  <Columns align="middle">
                    {sortObjects(favorites, 'createdAt', ['desc']).slice(0, 4).map((favorite) => {
                      return (
                        <Column span={{ xs: 12, md: 6 }} key={favorite.id}>
                          <FavoriteCard
                            favorite={favorite}
                            onUnFavoriteSuccess={handleFavoriteRemoved}
                            className={cn(marginClass([null, 'auto']), 'max--sm')}
                            onClick={() => { openGallery(favorite.model.id) }}
                          />
                        </Column>
                      )
                    })}
                  </Columns>
                </Pane>
              </Box>
            ) : (
              <Pane padding={[10, 6]} className="t--center">
                <strong>You don't have any favorites yet</strong>.
                <p className="c--gray600">A good first step is to <a href={`${process.env.REACT_APP_WEB_URL}/homes/plans`}>browse our plans</a>!</p>
              </Pane>
            )
          }
        </Box>
      </Container>
      <ImageGalleryOverlay
        isOpen={isGalleryOpen}
        assets={galleryImages}
        onClose={closeGallery}
        api={galleryApi}
      />
    </BaseTemplate>
  )
}
