import { Api, PortalType } from '@adair/core-client-utilities/lib/api';
import { BuildAreaApi } from '@adair/core-client-utilities/lib/build-area';
import { CustomerProfileApi } from '@adair/core-client-utilities/lib/customer-profile';
import { FavoriteApi } from '@adair/core-client-utilities/lib/favorite';
import { HomeownerApi } from '@adair/core-client-utilities/lib/homeowner';
import { HomeSiteApi } from '@adair/core-client-utilities/lib/home-site';
import { HomeQuoteApi } from '@adair/core-client-utilities/lib/home-quote';
import { ProfileApi } from '@adair/core-client-utilities/lib/profile';
import { ResourceApi } from '@adair/core-client-utilities/lib/resource';
import { SessionApi } from '@adair/core-client-utilities/lib/session';
import { TimelineApi } from '@adair/core-client-utilities/lib/timeline';
import { UserApi } from '@adair/core-client-utilities/lib/user';
import { DocumentApi } from '@adair/core-client-utilities/lib/document';

import { config } from '../config';

export const baseApi =  Api({
  baseUrl: config.api.baseUrl,
  portal: PortalType.CUSTOMER
});

export const api = {
  buildArea: BuildAreaApi(baseApi),
  customerProfile: CustomerProfileApi(baseApi),
  document: DocumentApi(baseApi),
  favorite: FavoriteApi(baseApi),
  homeowner: HomeownerApi(baseApi),
  homeQuote: HomeQuoteApi(baseApi),
  homeSite: HomeSiteApi(baseApi),
  profile: ProfileApi(baseApi),
  resources: ResourceApi(baseApi),
  session: SessionApi(baseApi),
  timeline: TimelineApi(baseApi),
  user: UserApi(baseApi),
}
