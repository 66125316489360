import React, { Fragment, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { isUndefined, reject } from 'lodash-es';
import useSWR from 'swr';
import { WebTour } from '@adair/core-client-utilities/lib/web-tour/types';
import { Favorite, isTourFavorite } from '@adair/core-client-utilities/lib/favorite';
import {
  Container, Columns, Column,
  Pane,
  Heading, Text,
  marginClass,
  isDefined,
  Loader,
} from '@adair/core-ui';

import { api } from '../../../util/api';
import { handleUnauthorized } from '../../../util/catch-401';
import { useUser } from '../../../hooks';
import { FavoriteCard } from '../../partials';
import { PageLoadError } from '../../partials';

interface FavoritesTourPageProps {
  children?: never;
}

export const FavoritesTourPage: React.FC<FavoritesTourPageProps> = (props) => {
  const user = useUser();
  const { data, isValidating, error, mutate } = useSWR(['favorites', user?.id], async () => {
    return (await api.favorite.fetchAll().request).data;
  });
  const tourFavorites = useMemo<Favorite<WebTour>[]>(() => {
    return isUndefined(data)
      ? []
      : data.filter((f) => {
        return isTourFavorite(f);
      })
  }, [data])

  useEffect(() => {
    if (isDefined(error)) {
      handleUnauthorized(error)
    }
  }, [error])

  function handleUnfavoriteStart(id: string) {
    const updatedFavorites = reject(tourFavorites, ['id', id]);
    mutate(updatedFavorites, false);
  }

  function handleUnfavoriteFailure() {
    // forces refetch of favorites to pull back removed favorite
    mutate();
  }

  function renderFavorites() {
    if (tourFavorites.length === 0)
      return (
        <Container maxWidth="md" margin={[8, null]}>
          <Pane bgColor="gray100" borderRadius="small" padding={[8, 6]} className="t--center">
            <Heading size={2} margin={[null, null, 2]}> You don't have any saved Tours yet</Heading>
            <Text color="gray600">
              You can <a href={`${process.env.REACT_APP_WEB_URL}/homes/tour`}>browse all tours</a> in one convenient location.
            </Text>
          </Pane>
        </Container>
      )

    return (
      <Container maxWidth="lg" padding={[8, null]}>
        <Columns align="middle">
          {tourFavorites.map((fave) => {
            return (
              <Column span={{ xs: 12, md: 6 }} key={fave.id}>
                <FavoriteCard
                  favorite={fave}
                  onUnFavoriteStart={() => handleUnfavoriteStart(fave.id)}
                  onUnFavoriteFailure={handleUnfavoriteFailure}
                  className={cn('max--sm', marginClass([null, 'auto']))}
                />
              </Column>
            )
          })}
        </Columns>
      </Container>
    )
  }

  return (
    <Fragment>
      <Loader isLoading={isValidating} />
      {error && error.response?.status !== 401
        ? (
          <Container padding={[8, null]} maxWidth="lg">
            <PageLoadError />
          </Container>
        )
        : renderFavorites()
      }
    </Fragment>
  )
}
