import { actionCreatorFactory } from 'typescript-fsa';

const PREFIX = 'ui';

const actionCreator = actionCreatorFactory(PREFIX);

export interface SEOParams {
  pageTitle?: string;
  pageDescription?: string;
}

const SEO = actionCreator<SEOParams>('SEO');
const showHeader = actionCreator<boolean>('SHOW_HEADER');
const showInfoBar = actionCreator<boolean>('SHOW_INFO_BAR');
const previewMode = actionCreator<boolean>('PREVIEW_MODE');

// Export

export const uiActions = {
  previewMode,
  SEO,
  showHeader,
  showInfoBar
}
