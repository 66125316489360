import React, { useState, useEffect, Fragment } from 'react';
import { format } from 'date-fns';
import cn from 'classnames';
import useSWR from 'swr';
import { HomeQuote } from '@adair/core-client-utilities/lib/home-quote';
import {
  Columns, Column, Container,
  Box,
  Buttons, Button,
  Table,
  Pane,
  Tooltip,
  Message, Messages,
  Loader,
  Heading, Text,
  Hr,
  Dialog,
  toaster,
  marginClass,
  sortObjects,
  Tag,
  isDefined,
} from '@adair/core-ui';
import { IconInfoCircle, IconDownload } from '@adair/core-ui/lib/icons';


import { api } from '../../util/api';
import { reporter } from '../../util/reporter';
import { useUser } from '../../hooks';
import { handleUnauthorized } from '../../util/catch-401';
import { BaseTemplate } from '../templates';
import { PageLoadError } from '../partials/error/page-load-error';
import { SEO } from '../partials';


export const HomeQuotePage: React.FC = () => {
  const user = useUser();
  const { error, data: quotes, isValidating } = useSWR(['quotes', user?.id], async () => {
    return (await api.homeQuote.fetchAll().request).data;
  });
  const [activeQuote, setActiveQuote] = useState<HomeQuote | null>(null);
  const sortedQuotes = quotes ? sortObjects(quotes, 'lastModifiedAt', ['desc']) : [];

  useEffect(() => {
    if (isDefined(error)) {
      handleUnauthorized(error)
    }
  }, [error])

  const viewQuote = (quote: HomeQuote) => () => {
    setActiveQuote(quote);
  }

  const downloadQuote = (quote: HomeQuote) => () => {
    if (quote.documents.length === 0) return;

    const downloadDoc = sortObjects(
      quote.documents.filter((d) => d.documentType === 'quote-binder'),
      'contentModifiedDate'
    )[0];

    if (!downloadDoc) {
      reporter.notify({
        message: 'Received Homequote with unexpected Document types',
        context: { documennts: quote.documents },
      });
      toaster.danger({ text: 'We encountered an error when trying to download your quote' });
      return;
    };

    api.document.download(downloadDoc.id)
      .catch((err) => {
        toaster.danger({
          text: "There was an Error trying to retrieve your document."
        })
      })
  }

  function closeDrawer() {
    setActiveQuote(null);
  }

  function renderQuotePreview(quote: HomeQuote) {
    return (
      <Column key={quote.id} span={{ xs: 12, md: 6 }}>
        <Box density="relaxed" appearance="fill" className={cn('max--sm')} margin={[null, 'auto']}>
          <Pane padding={[6, 8, 6]} className="t--center">
            <Heading size={1} className={cn('c--gray500', marginClass([null, null, 8]))}>
              {format(quote.lastModifiedAt, 'M/dd/y')}
            </Heading>
            <Text as="p" margin={[null, null, 1]} size={2}>
              {quote.homeSite.siteAddress}
            </Text>
            <Heading size={3} margin={[null, null, 8]}>
              {quote.elevationName}
            </Heading>
            <Text size={2} color="gray600">
              <sup>$</sup><strong>{currency(quote.grandTotal)}</strong>
            </Text>
          </Pane>
          <Hr />
          <Pane padding={[2, 6]}>
            <Buttons density="compact" appearance="minimal" flexJustify="center">
              <Button label="View" onClick={viewQuote(quote)} />
              {quote.documents.length > 0 &&
                <Button label={<IconDownload />} onClick={downloadQuote(quote)} />
              }
            </Buttons>
          </Pane>
        </Box>
      </Column>
    )
  }

  function renderLineItem(category: React.ReactNode, amount: number | null, helpText?: React.ReactNode) {
    return (
      <tr>
        <td>{category} {helpText && renderLearnMore(helpText)}</td>
        <CurrencyColumn amount={amount} />
      </tr>
    )
  }

  function renderLearnMore(text: React.ReactNode) {
    return (
      <Tooltip content={text}>
        <IconInfoCircle className={cn('c--gray500', marginClass([null, 2]))} />
      </Tooltip>
    )
  }

  function renderQuote(quote: HomeQuote | null) {
    if (!quote) return null;
    return (
      <Fragment>
        <Pane padding={[4, 6]} border={{ width: 'small', location: "bottom" }} borderColor="gray200">
          <Columns align="middle">
            <Column className={cn('d--none', 'd--block::sm')}>
              <Heading size={3}>Home Quote</Heading>
            </Column>
            {quote.documents.length > 0 &&
              <Column span="shrink">
                <Button
                  intent="primary"
                  label="Download PDF"
                  after={<IconDownload />}
                  onClick={downloadQuote(quote)}
                />
              </Column>}
          </Columns>
        </Pane>
        <Dialog.Body className="bg--gray100 rounded--b-md">
          <Pane padding={[6, 4]}>
            <Heading size={1} margin={[null, null, 4]}>Product Information</Heading>
            <Box>
              <Table density="compact" divide="horizontal">
                <tbody>
                  <tr className="-valign--baseline" >
                    <td className="f--sm -shrink"><strong>Model</strong></td>
                    <td>{quote.elevationName}</td>
                  </tr>
                  <tr className="-valign--baseline">
                    <td className="f--sm -shrink"><strong>Orientation</strong></td>
                    <td>{quote.planOrientation}</td>
                  </tr>
                </tbody>
              </Table>
            </Box>
          </Pane>
          <Pane padding={[6, 4]}>
            <Heading size={1} margin={[null, null, 4]}>Order Summary</Heading>
            <Box>
              <Table divide="all">
                <colgroup>
                  <col />
                  <col style={{ width: '1%' }} />
                </colgroup>
                <tbody>
                  {renderLineItem('Model Base Price', quote.basePrice)}
                  {renderLineItem('Standard Options', quote.standardOptionTotal)}
                  {renderLineItem('Custom Options', quote.customOptionTotal)}
                  {renderLineItem(
                    <Fragment>
                      Mileage Charge <span className="t--mono f--sm c--gray600">({quote.homeSite.mileageDistance} mi)</span>
                    </Fragment>,
                    quote.mileageCharge
                  )}
                  {renderLineItem('Design Fees', quote.designFees)}
                  {renderLineItem('Engineering Fees', quote.engineeringFees)}
                </tbody>
                <tbody>
                  <tr className={'t--right -valign--baseline'}>
                    <td className="border--t-4">Taxable Subtotal</td>
                    <td className={'t--mono border--t-4'}>
                      <sup className={cn('c--gray400', marginClass([null, 1, null, null]))}>$</sup>
                      {currency(quote.taxableSubtotal)}
                    </td>
                  </tr>
                  <tr className={'t--right'}>
                    <td>
                      Sales Tax <span className="t--mono f--sm c--gray600">({quote.homeSite.salesTaxRate ?? 0}%)</span>
                      {quote.homeSite.siteState === 'AZ' &&
                        renderLearnMore('Arizona Sales Tax is calculated by multiplying 65% of the subtotal by the jurisdictional tax rate.')
                      }
                    </td>
                    <CurrencyColumn amount={quote.salesTax} />
                  </tr>
                  <tr className={'t--right'}>
                    <td>Nontaxable Subtotal</td>
                    <CurrencyColumn amount={quote.nonTaxableSubtotal} />
                  </tr>
                  <tr className={'t--right -valign--baseline'}>
                    <td className="heading">Adair Contract Total</td>
                    <td className={cn('t--mono', 'bg--gray200')}>
                      <sup className={cn('c--gray400', marginClass([null, 1, null, null]))}>$</sup>
                      <strong>{currency(quote.grandTotal)}</strong>
                    </td>
                  </tr>
                  <tr className={'t--right'}>
                    <td>Engineering Fees Received</td>
                    <CurrencyColumn amount={quote.engineeringFees ? -quote.engineeringFees : null} tabulationView={false} />
                  </tr>
                  <tr className={'t--right'}>
                    <td>Nonrefundable Processing Fees Received</td>
                    <CurrencyColumn amount={quote.processingFees} tabulationView={false} />
                  </tr>
                  <tr className={'t--right'}>
                    <td>Nonrefundable Design Services Fees Received</td>
                    <CurrencyColumn amount={quote.conceptFees} tabulationView={false} />
                  </tr>
                  {!!quote.turnKeyDeposit &&
                    <tr className={'t--right'}>
                      <td>Nonrefundable Turn Key Deposit Received</td>
                      <CurrencyColumn amount={quote.turnKeyDeposit} tabulationView={false} />
                    </tr>
                  }
                  <tr className={'t--right -valign--baseline'}>
                    <td className="heading">Agreement Balance</td>
                    <td className={'t--mono'}>
                      <sup className={cn('c--gray400', marginClass([null, 1, null, null]))}>$</sup>
                      <strong>{currency(quote.agreementBalance)}</strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Box>
          </Pane>
          <Pane padding={[6, 4]}>
            <Messages appearance="outline">
              <Message showIcon intent="warning">
                Please note that the Adair Contract Total does not include the total
                cost to build a house. Land acquisition, site development, finance costs,
                and other items of owner responsibility must be evaluated to develop
                a total project cost. The model base price, standard options, and
                custom option prices, are subject to change without notice prior
                to order ratification.
              </Message>
              <Message intent="info">
                For a detailed breakdown of options, fees, and other information
                please download the PDF document.
              </Message>
            </Messages>
          </Pane>
        </Dialog.Body>
        <Pane padding={[4, 6]} border={{ width: 'small', location: "top" }} borderColor="gray300">
          <ul className="list -inline f--sm">
            <li><strong>Last Update:</strong> {format(quote.lastModifiedAt, 'MMMM dd, yyyy')}</li>
          </ul>
        </Pane>
      </Fragment>
    )
  }

  function renderQuoteList() {
    return (
      <Container padding={[8, null]} maxWidth="lg">
        <Columns>
          {sortedQuotes.length === 0 && !isValidating &&
            <Column>
              <Pane margin={[null, null, 8]} className="t--center">
                <Tag size="large" intent="warning">Coming Soon</Tag>
              </Pane>
              <Heading size={5} margin={[null, null, 4]} className="t--center">
                View & download customized home quotes from your local Adair Design Center.
              </Heading>
              <p className="t--center f--2">
                <a href={`${process.env.REACT_APP_WEB_URL}/contact`}>Contact us</a> to get a home quote today.
              </p>
            </Column>
          }
          {sortedQuotes.map(renderQuotePreview)}
        </Columns>
      </Container>
    )
  }

  return (
    <BaseTemplate navTitle="Home Quotes">
      <SEO title="My Home Quotes" />
      <Loader isLoading={isValidating} />

      {error && error.response?.status !== 401
        ? (
          <Container padding={[8, null]} maxWidth="lg">
            <PageLoadError />
          </Container>
        )
        : renderQuoteList()
      }

      <Dialog
        isOpen={activeQuote !== null}
        size="md"
        onClose={closeDrawer}
      >
        {renderQuote(activeQuote)}
      </Dialog>
    </BaseTemplate>
  );
}

function currency(value: number | null) {
  if (value === null) return '0.00';

  let formattedValue = Math.abs(value).toFixed(2);
  formattedValue = formattedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  formattedValue = value < 0 ? `(${formattedValue})` : formattedValue;

  return formattedValue;
}

const CurrencyColumn = (props: { amount: number | null, tabulationView?: boolean }) => {
  const { amount, tabulationView = true } = props;
  return (
    <td>
      <div className={'t--mono t--right d--flex justify--between'}>
        <span className={cn('c--gray300', marginClass([null, 4, null, null]))}>
          {!amount || amount === 0 || !tabulationView ? '' : amount < 0 ? '-' : '+'}
        </span>
        <span>
          <sup className={cn('c--gray500', marginClass([null, 1, null, null]))}>$</sup>
          {currency(amount)}
        </span>
      </div>
    </td>
  )
}
