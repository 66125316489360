import React, { useState } from 'react';
import { format as formatDate } from 'date-fns';
import cn from 'classnames';
import {
  Columns, Column,
  Tooltip,
  Buttons, Button,
  Heading, Text,
  toaster,
  Pane,
  IPaneProps,
} from '@adair/core-ui'
import { IconDownload } from '@adair/core-ui/lib/icons';
import { Document } from '@adair/core-client-utilities/lib/document';

import { api } from '../../../util/api'

export interface DocumentTileProps extends IPaneProps {
  document: Document;
}

export const DocumentTile: React.FC<DocumentTileProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  function downloadDocument(document: Document) {
    setIsDownloading(true);
    api.document.download(document.id)
      .catch((err) => {
        toaster.danger({
          heading: 'There was an error downloading your document',
          text: 'If the issue persists, please contact us for assistance.',
        })
      })
      .then(() => {
        setIsDownloading(false);
      })

  }

  function viewDocument(document: Document) {
    setIsLoading(true);
    api.document.view(document.id)
      .catch((err) => {
        toaster.danger({
          heading: 'There was an error viewing your document',
          text: 'If the issue persists, please contact us for assistance.',
        })
      })
      .then(() => {
        setIsLoading(false);
      })
  }
  const { document, ...paneProps } = props;

  return (
    <Pane padding={[6]} borderRadius="medium" {...paneProps}>
      <Columns align="middle">
        <Column>
          <Heading size={1} margin={[null, null, 1]}>
            {document.displayName || document.title}
          </Heading>
          <Text size="sm" color="gray600" className={cn('t--cap')}>
            {formatDate(document.lastModifiedDate, 'LLL d, yyyy')} • {document.fileType}
          </Text>
        </Column>
        <Column span="shrink">
          <Buttons>
            <Button
              label='View'
              appearance="outline"
              size="small"
              isLoading={isLoading}
              onClick={() => { viewDocument(document) }}
            />
            <Tooltip content="Download" hoverOpenDelay={500}>
              <Button
                label={<IconDownload size="small" />}
                isSquare
                intent="primary"
                size="small"
                isLoading={isDownloading}
                onClick={() => { downloadDocument(document) }}
              />
            </Tooltip>
          </Buttons>
        </Column>
      </Columns>
    </Pane>
  )
}